

import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { InvitationStatusTypes, SharedMealTypes } from 'src/app/models/constants';
import { TripDisplay } from 'src/app/models/trip-display';
import { CamperProfile, CamperProfileResponse, SharedMeal, Trip } from 'src/app/services/treelove-api.service';

@Component({
  selector: 'app-shared-meal-selection-modal',
  templateUrl: './shared-meal-selection-modal.component.html',
  styleUrls: ['./shared-meal-selection-modal.component.scss'],
})
export class SharedMealSelectionModalComponent implements OnInit {
  
  @Input() trip: TripDisplay;
  @Input() currentCamper: CamperProfile;
  @Input() item: SharedMeal = new SharedMeal();
  mealsToPick: string[];
  campersToPIck: CamperProfile[];
  daysToPick: Date[] = [];
  dontKnowText = "I don't know";
  public mealForm = new FormGroup({
    name: new FormControl('', [ Validators.required ]),
    mealDay: new FormControl('', [ Validators.required ]),
    mealType: new FormControl('', [ Validators.required ]),
    providerId: new FormControl('', [ Validators.required ]),
    requireRSVP: new FormControl(false, []),
    notes: new FormControl('', [ ])
  }
);
  constructor(private _modalController: ModalController) { }

  ionViewDidEnter() {
    const modalCardHeight = document.querySelector('.modal-card-wrapper')?.clientHeight;
    const modalCardWidth = document.querySelector('.modal-card-wrapper')?.clientWidth;
    document.documentElement.style.setProperty('--current-modal-card-height', `${modalCardHeight}px`);
    document.documentElement.style.setProperty('--current-modal-card-width', `${modalCardWidth}px`);
    this.setupOptions();
  }

  ngOnInit() {}

  onClose(event) {
    this._modalController.dismiss(null);
  }

  onSave(event) {
    if(!this.mealForm.valid) {
      this.mealForm.markAllAsTouched();
    } else {
      this.item.name = this.mealForm.controls.name.value;
      this.item.mealDay = this.mealForm.controls.mealDay.value != this.dontKnowText ? new Date(this.mealForm.controls.mealDay.value) : null;
      this.item.mealType = this.mealForm.controls.mealType.value;
      this.item.doesRequireRSVP = this.mealForm.controls.requireRSVP.value;
      this.item.notes = this.mealForm.controls.notes.value;
      this.item.providerId = this.mealForm.controls.providerId.value;
      this._modalController.dismiss(this.item);
    }
  }

  setupOptions() {
    if(this.trip.currentCamperIsHost) {
      this.campersToPIck = this.trip.attendees.filter(x => x.status === InvitationStatusTypes.ACCEPTED)
        .filter(x => x.camper.id !== this.currentCamper.id)
        .map(x => x.camper);
    } else {
      this.mealForm.controls.providerId.setValue(this.currentCamper.id);
    }

    
        
    
    if(this.trip.startDateUtc && this.trip.endDateUtc) {
      var startDate = new Date(this.trip.startDateUtc);
      var endDate = new Date(this.trip.endDateUtc);

      while (startDate <= endDate) {
        var dayToPick = new Date(startDate);
        this.daysToPick.push(dayToPick);
        startDate.setDate(startDate.getDate() + 1);
      }
    }
    this.mealsToPick = [
      "I don't know",
      SharedMealTypes.BREAKFAST,
      SharedMealTypes.LUNCH,
      SharedMealTypes.DINNER,
      SharedMealTypes.SNACK,
      SharedMealTypes.OTHER,
    ];

    if(this.item.id) {
      this.mealForm.controls.name.setValue(this.item.name);
      this.mealForm.controls.mealType.setValue(this.item.mealType);
      this.mealForm.controls.requireRSVP.setValue(this.item.doesRequireRSVP);
    }
        
  }
  

}
