import { Component } from '@angular/core';
import { BUILD_TIMESTAMP } from '../environments/build-timestamp';
import { MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { Router } from '@angular/router';
import { AccountsService } from './services/accounts.service';
import { CamperWithTripsAndFriends } from './models/camper';
import { Location } from '@angular/common';
import { Storage } from '@ionic/storage-angular';
import { User } from 'firebase/auth';
import { HeaderGuard } from './guards/header.guard';
import { HeaderDisplay } from './models/header-display';
import { AuthService } from './auth/auth.service';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { CamperProfile } from './services/treelove-api.service';
import { NotificationsService } from './services/notification.service';
import { StorageService } from './services/storage.service';
// import { NotificationsService } from './services/notification.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Trips',
      url: '/trips',
      icon: 'bonfire-sharp'
    },
    {
      title: 'Friends',
      url: '/friends',
      icon: 'people-circle'
    },
    // {
    //   title: 'Grasshopper',
    //   url: '/travel-game',
    //   icon: 'people-outline'
    // }
  ];

  public currentCamper: CamperProfile;
  public hasFriendshipRequests = false;
  selectedPageName: string;
  buildTimestamp = BUILD_TIMESTAMP;
  

  constructor(private platform: Platform, private statusBar: StatusBar, 
    private _authService: AuthService, private _router: Router, private _menu: MenuController,
    private _location: Location, private _headerGuard : HeaderGuard, private _storageService: StorageService,
    private _accountsService: AccountsService, // keep this here, please (it is to initialize the AccountsService early)
    private _notificationsService: NotificationsService, // keep this here, please (it is to initialize the NotificationsService early)
    ) {
    this.registerBackButtonBehavior();
    // this._headerGuard.headerDisplaySubject.subscribe((headerDisplay: HeaderDisplay) => {
    //   if(headerDisplay) {
    //     this.selectedPageName = headerDisplay.selectedPageName;
    //   }
    // });

    this._storageService.get(this.hiddenFeatureStorageKey).then((showConnect4Ever) => {
        this.showConnect4Ever = showConnect4Ever;
    });
    
    this._accountsService.currentCamperWithTripsAndFriendsSubject.subscribe((currentCamper: CamperWithTripsAndFriends) => {
      if(currentCamper) {
        this.currentCamper = currentCamper.camperProfile;
        if(currentCamper.friendshipRequests?.length > 0) {
          this.hasFriendshipRequests = true;
        }
      
      }
      
    });
    this.initializeApp();
  }

  allowNotifications() {
    this._notificationsService.requestPermission();
  }

  async signOut() {
    await this._authService.signOut();
    this._router.navigateByUrl('/auth-flow/sign-in');
    this._menu.close();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      SplashScreen.hide();
    });
  }

  refresh() {
    window.location.reload();
  }

  registerBackButtonBehavior() {
    this.platform.backButton.subscribeWithPriority(100, async (processNextHandler) => {
      var currentNav = this._router.url;
      if (this._router.url != '/home') {
          // await this.router.navigate(['/']);
          await this._location.back();
        } else {
          navigator['app'].exitApp();
        }
    });

    

    

    // this.platform.backButton.subscribeWithPriority(0, () => {
    //   this.routerOutlets.forEach(async(outlet: IonRouterOutlet) => {
    //     if (this.router.url != '/home') {
    //       // await this.router.navigate(['/']);
    //       await this.location.back();
    //     } else if (this.router.url === '/home') {
    //       if (new Date().getTime() - this.lastTimeBackPress >= this.timePeriodToExit) {
    //         this.lastTimeBackPress = new Date().getTime();
    //         this.presentAlertConfirm();
    //       } else {
    //         navigator['app'].exitApp();
    //       }
    //     }
    //   });
    // });
  }

  clickCount = 0;
  clickTimeout;
  onHiddenFeatureButtonClick() {
    this.clickCount++;
    if (this.clickCount === 1) {
      this.clickTimeout = setTimeout(() => {
        this.clickCount = 0;
      }, 2000); // 2-second window for 9 clicks
    }

    if (this.clickCount === 9) {
      clearTimeout(this.clickTimeout);
      this.clickCount = 0;
      this.activateHiddenFeature();
    }
  }
  hiddenFeatureStorageKey = "hiddenFeatureStorageKey";
  showConnect4Ever: boolean = false;
  private async activateHiddenFeature() {
    this._storageService.set(this.hiddenFeatureStorageKey, true);
    this.showConnect4Ever = true;
  }
}
