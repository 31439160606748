import { Component, OnInit } from '@angular/core';
import { HeaderGuard } from 'src/app/guards/header.guard';
import { HeaderDisplay } from 'src/app/models/header-display';

@Component({
  selector: 'app-tl-header',
  templateUrl: './tl-header.component.html',
  styleUrls: ['./tl-header.component.scss'],
})
export class TlHeaderComponent implements OnInit {
showAuthTopper: boolean;
  constructor(private _headerGuard: HeaderGuard) { 
    this._headerGuard.headerDisplaySubject.subscribe((headerDisplay: HeaderDisplay) => {
      this.showAuthTopper = headerDisplay?.showAuthTopper;
    });
  }

  ngOnInit() {}

}
