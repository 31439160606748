
<div class="modal-wrapper">
  <ion-card class="modal-card-wrapper">
    <ion-card-header class="logo-wrapper">
      <h1 class="title">New Connect4Ever Game!</h1>
      <ion-icon class="close-icon clickable" (click)="onClose()" name="close-outline"></ion-icon>
      <!-- <span class="close-icon clickable" (click)="onSave($event)">Save</span> -->
    </ion-card-header>
  
    <ion-card-content class="modal-content-wrapper">
      <form [formGroup]="newGameForm">
        <ion-row>
          <ion-col>
            <ion-item style="min-width: 84vw;" class="bordered-item" lines="none">
              <ion-select cancelText="Cancel" okText="Done" [formControlName]="'opponentId'" placeholder="Who are you challenging?">
                <ion-select-option *ngFor="let friend of friends" [value]="friend.id">{{ friend.name }}</ion-select-option>
              </ion-select>
              <ion-icon slot="end" name="fast-food-outline"></ion-icon>
            </ion-item>
            <div *ngIf="newGameForm.controls.opponentId.invalid && newGameForm.controls.opponentId.touched">
              <ion-text color="danger" *ngIf="newGameForm.controls.opponentId.errors?.['required']">
                  Required Field
              </ion-text>
            </div>
          </ion-col>
        </ion-row>

        <div>
          <ion-row>
            <ion-col class="ion-text-center">
              <p class="text-notice">How many rows to start with?</p>
            </ion-col>
          </ion-row>
          <ion-row class="ion-align-items-center">
            <ion-col offset="1" size="3">
              <ion-button (click)="decreaseRows()" class="quantity-selection-button"><ion-icon slot="icon-only" name="remove-outline"></ion-icon></ion-button>
            </ion-col>
            <ion-col size="4" class="ion-align-self-center ion-text-center">
              <h2 class="quantity-selection-quantity">{{ newGameForm.controls.rows.value }}</h2>
            </ion-col>
            <ion-col size="3">
              <ion-button (click)="increaseRows()"
              class="quantity-selection-button"><ion-icon slot="icon-only" name="add-outline"></ion-icon></ion-button>
            </ion-col>
          </ion-row>
        </div>

        <div>
          <ion-row>
            <ion-col class="ion-text-center">
              <p class="text-notice">How many columns? </p>
            </ion-col>
          </ion-row>
          <ion-row class="ion-align-items-center">
            <ion-col offset="1" size="3">
              <ion-button (click)="decreaseCols()" class="quantity-selection-button"><ion-icon slot="icon-only" name="remove-outline"></ion-icon></ion-button>
            </ion-col>
            <ion-col size="4" class="ion-align-self-center ion-text-center">
              <h2 class="quantity-selection-quantity">{{ newGameForm.controls.cols.value }}</h2>
            </ion-col>
            <ion-col size="3">
              <ion-button (click)="increaseCols()"
              class="quantity-selection-button"><ion-icon slot="icon-only" name="add-outline"></ion-icon></ion-button>
            </ion-col>
          </ion-row>
        </div>

        <div>
          <ion-row>
            <ion-col class="ion-text-center">
              <p class="text-notice">Play to? </p>
            </ion-col>
          </ion-row>
          <ion-row class="ion-align-items-center">
            <ion-col offset="1" size="3">
              <ion-button (click)="decreasePoints()" class="quantity-selection-button"><ion-icon slot="icon-only" name="remove-outline"></ion-icon></ion-button>
            </ion-col>
            <ion-col size="4" class="ion-align-self-center ion-text-center">
              <h2 class="quantity-selection-quantity">{{ newGameForm.controls.points.value }}</h2>
            </ion-col>
            <ion-col size="3">
              <ion-button (click)="increasePoints()"
              class="quantity-selection-button"><ion-icon slot="icon-only" name="add-outline"></ion-icon></ion-button>
            </ion-col>
          </ion-row>
        </div>

        <div>
          <ion-row>
            <ion-col class="ion-text-center">
              <p class="text-notice">Win by? </p>
            </ion-col>
          </ion-row>
          <ion-row class="ion-align-items-center">
            <ion-col offset="1" size="3">
              <ion-button (click)="decreaseWinByThreshold()" class="quantity-selection-button"><ion-icon slot="icon-only" name="remove-outline"></ion-icon></ion-button>
            </ion-col>
            <ion-col size="4" class="ion-align-self-center ion-text-center">
              <h2 class="quantity-selection-quantity">{{ newGameForm.controls.winByThreshold.value }}</h2>
            </ion-col>
            <ion-col size="3">
              <ion-button (click)="increaseWinByThreshold()"
              class="quantity-selection-button"><ion-icon slot="icon-only" name="add-outline"></ion-icon></ion-button>
            </ion-col>
          </ion-row>
        </div>
      </form>
      
      <ion-row>
        <ion-col>
            <ion-button (click)="onSave()">Save</ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button (click)="onClose()">Cancel</ion-button>
        </ion-col>
      </ion-row>
    </ion-card-content>
  </ion-card>


</div>
