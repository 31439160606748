<ion-grid>
  <ion-row>
    <ion-col>
      <h2 *ngIf="chooseCamperText">{{ chooseCamperText }}</h2>
      <h2 *ngIf="!chooseCamperText">Choose Camper</h2>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <span *ngFor="let camper of campers">
        <app-treelove-avatar (click)="dismiss(camper)" [camper]="camper"></app-treelove-avatar>
      </span>
    </ion-col>
  </ion-row>
</ion-grid>


