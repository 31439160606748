
  <div class="modal-wrapper">
    <ion-card class="modal-card-wrapper">
      <ion-card-header class="logo-wrapper">
        <ion-icon class="close-icon clickable" (click)="onClose($event)" name="close-outline"></ion-icon>
        <!-- <span class="close-icon clickable" (click)="onSave($event)">Save</span> -->
      </ion-card-header>
    
      <ion-card-content class="modal-content-wrapper">
        <ion-row>
          <ion-col class="ion-text-center">
            <p class="text-notice">{{ mainText }}</p>
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center">
          <ion-col offset="1" size="3">
            <ion-button (click)="decreaseQuantity()" class="quantity-selection-button"><ion-icon slot="icon-only" name="remove-outline"></ion-icon></ion-button>
          </ion-col>
          <ion-col size="4" class="ion-align-self-center ion-text-center">
            <h2 class="quantity-selection-quantity">{{ quantity }} / {{ total }}</h2>
          </ion-col>
          <ion-col size="3">
            <ion-button (click)="increaseQuantity()"
             class="quantity-selection-button"><ion-icon slot="icon-only" name="add-outline"></ion-icon></ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
              <ion-button (click)="onSave($event)">Save</ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button (click)="onClose($event)">Cancel</ion-button>
          </ion-col>
        </ion-row>

        <!-- <div>
          <p class="text-notice">{{ mainText }}</p>
          <ion-button class="quantity-selection-button"><ion-icon slot="icon-only" name="remove-outline"></ion-icon></ion-button>
          <h2 class="quantity-selection-quantity">{{ quantity }} / {{ total }}</h2>
          <ion-button class="quantity-selection-button"><ion-icon slot="icon-only" name="add-outline"></ion-icon></ion-button>

            <ion-list>
              <ion-item [lines]="'none'">
                <ion-input inputmode="numeric" [(ngModel)]="quantity" placeholder="How many?" type="number" description="numeric"></ion-input>
              </ion-item>
            </ion-list>
    
            <ion-item-divider></ion-item-divider>
            <div class="action-btn-wrapper">
              <ion-button (click)="onSave($event)">Save</ion-button>
              <ion-button (click)="onClose($event)">Cancel</ion-button>
            </div>
        </div> -->
      </ion-card-content>
    </ion-card>
  </div>
