import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { PackingCategoryTypes, PriorityTypes } from 'src/app/models/constants';
import { InventoryCatalogItemDisplay } from 'src/app/models/inventory-catalog-item-display';
import { InventoryService } from 'src/app/services/inventory.service';
import { InventoryCatalogItem, PersonalItem, Trip } from 'src/app/services/treelove-api.service';
import * as fuzzysort from "fuzzysort";

@Component({
  selector: 'app-personal-item-creation-modal',
  templateUrl: './personal-item-creation-modal.component.html',
  styleUrls: ['./personal-item-creation-modal.component.scss'],
})
export class PersonalItemCreationModalComponent implements OnInit {
  
  @Input() trip: Trip;
  @Input() isPrivate: boolean = false;
  @Input() inventoryCatalogItemDisplays: InventoryCatalogItemDisplay[] = [];
  priorityTypesList: string[] = [
    PriorityTypes.IMPORTANT,
    PriorityTypes.OPTIONAL
  ];
  priorityTypes = PriorityTypes;
  categoryTypes: string[] = [
    PackingCategoryTypes.ACCESSORIES,
    PackingCategoryTypes.CAMP_SITE,
    PackingCategoryTypes.CLOTHING,
    PackingCategoryTypes.ENTERTAINMENT,
    PackingCategoryTypes.EQUIPMENT,
    PackingCategoryTypes.FOOD,
    PackingCategoryTypes.HEALTH,
    PackingCategoryTypes.HYGIENE,
    PackingCategoryTypes.KITCHEN,
    PackingCategoryTypes.MISCELLANEOUS,
    PackingCategoryTypes.TOOLS,
    PackingCategoryTypes.TRANSPORTATION
  ];

  searchText: string;
  shouldViewItemsByCategory: boolean = false;
  isCreatingNewItem: boolean = false;
  newItem: InventoryCatalogItemDisplay;
  
  inventoryCatalogItemsToShow: InventoryCatalogItemDisplay[];

  public personalItemForm = new FormGroup({
    name: new FormControl('', [ Validators.required ]),
    priorityType: new FormControl('', [ Validators.required ]),
    categoryType: new FormControl('', [ Validators.required ]),
    notes: new FormControl('', [ ])
  });
  constructor(private _modalController: ModalController, private _inventoryService: InventoryService) { 
    
  }

  ngOnInit() {
    if(this.inventoryCatalogItemDisplays?.some(x => true)) {
      var items = this.consolidateAndSortItems(this.inventoryCatalogItemDisplays);
        this.inventoryCatalogItemDisplays = [ ...items ];
        this.inventoryCatalogItemsToShow = [ ...items ];
    } else {
      this._inventoryService.inventoryCatalogItemDisplaysSubject.subscribe((inventoryCatalogItemDisplays: InventoryCatalogItemDisplay[]) => {
        if(inventoryCatalogItemDisplays) {
          var items = this.consolidateAndSortItems(inventoryCatalogItemDisplays);
          this.inventoryCatalogItemDisplays = [ ...items ];
          this.inventoryCatalogItemsToShow = [ ...items ];
        }
      });
    }
    
  }

  consolidateAndSortItems(catalogItems: InventoryCatalogItemDisplay[]) {
    var copiedCatalogItems = catalogItems.map(x => {
      var item = new InventoryCatalogItem({
        campingType: x.campingType,
        category: x.category,
        name: x.name,
        priority: x.priority,
        weatherType: x.weatherType
      });
      return new InventoryCatalogItemDisplay(item);
    });
    this.trip.personalItems.forEach(item => {
      var catalogItem = copiedCatalogItems.find(x => x.name === item.name);
      if(catalogItem) {
        catalogItem.isSelected = true;
        catalogItem.priority = item.priority;
        catalogItem.requiredCount = item.requiredCount;
        catalogItem.alreadyExists = true;
      }
    });

    return copiedCatalogItems.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  ionViewDidEnter() {
    const modalCardHeight = document.querySelector('.modal-card-wrapper')?.clientHeight;
    const modalCardWidth = document.querySelector('.modal-card-wrapper')?.clientWidth;
    document.documentElement.style.setProperty('--current-modal-card-height', `${modalCardHeight}px`);
    document.documentElement.style.setProperty('--current-modal-card-width', `${modalCardWidth}px`);
  }

  increaseQuantity(catalogItem: InventoryCatalogItemDisplay) {
      catalogItem.requiredCount += 1;
  }

  decreaseQuantity(catalogItem: InventoryCatalogItemDisplay) {
    if(catalogItem.requiredCount > 1) {
      catalogItem.requiredCount -= 1;
    }
  }

  createNewItem() {
    this.newItem = new InventoryCatalogItemDisplay(new InventoryCatalogItem());
    if(this.searchText) {
      this.personalItemForm.controls.name.setValue(this.searchText);
    }
    this.isCreatingNewItem = true;
  }
 

  

  onClose() {
    this._modalController.dismiss(null);
  }

  onSave() {
    if(this.isCreatingNewItem) {
      this.newItem.isSelected = true;
      this.newItem.category = this.personalItemForm.controls.categoryType.value;
      this.newItem.name = this.personalItemForm.controls.name.value;
      this.newItem.priority = this.personalItemForm.controls.priorityType.value;
      // this.newItem.notes = this.personalItemForm.controls.notes.value;

      this.inventoryCatalogItemDisplays.unshift(this.newItem);
      this.inventoryCatalogItemsToShow = this.inventoryCatalogItemDisplays;
      this.isCreatingNewItem = false;
      this.newItem = null;
      this.personalItemForm = new FormGroup({
        name: new FormControl('', [ Validators.required ]),
        priorityType: new FormControl('', [ Validators.required ]),
        categoryType: new FormControl('', [ Validators.required ]),
        notes: new FormControl('', [ ])
      });
    } else {
      var itemsToSave = this.inventoryCatalogItemDisplays.filter(x => !x.alreadyExists && x.isSelected).map(x => {
        return new PersonalItem({
          isPrivate: this.isPrivate,
          category: x.category,
          requiredCount: x.requiredCount,
          name: x.name,
          priority: x.priority,
          tripId: this.trip.id
        })
      });
      this._modalController.dismiss(itemsToSave);

    }
    
  }

  onSearch(event) {
    if(this.searchText && this.searchText !== "") {
      this.searchInventory(this.searchText).then((results: Fuzzysort.KeyResults<InventoryCatalogItemDisplay>) => {
        if(results) {
          this.inventoryCatalogItemsToShow = results.map(x => x.obj);
        }
      });
    }
  }

  onClearSearch() {
    this.inventoryCatalogItemsToShow = [ ...this.inventoryCatalogItemDisplays ];
  }

  public searchInventory(searchText: string) {
    var objects = this.inventoryCatalogItemDisplays;
    var promise = fuzzysort.goAsync(searchText, objects, {
      key: 'name'
    });
    
    return promise;
  }

}
