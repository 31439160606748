
<div class="modal-wrapper">
  <ion-card class="modal-card-wrapper">
    <ion-card-header class="logo-wrapper">
      <h1 class="title">Shared Meal</h1>
      <ion-icon class="close-icon clickable" (click)="onClose($event)" name="close-outline"></ion-icon>
      <!-- <span class="close-icon clickable" (click)="onSave($event)">Save</span> -->
    </ion-card-header>
  
    <ion-card-content class="modal-content-wrapper">
      <form [formGroup]="mealForm">

        <ion-row *ngIf="trip.currentCamperIsHost">
          <ion-col>
            <ion-item style="min-width: 84vw;" class="bordered-item" lines="none">
                <ion-select  cancelText="Cancel" okText="Done" [formControlName]="'providerId'" placeholder="Who is bringing it?">
                  <ion-select-option [value]="currentCamper.id">Me</ion-select-option>
                  <ion-select-option *ngFor="let camper of campersToPIck" [value]="camper.id">{{ camper.name }}</ion-select-option>
                </ion-select>
              <ion-icon slot="end" name="accessibility-outline"></ion-icon>
            </ion-item>
            <div *ngIf="mealForm.controls.providerId.invalid && mealForm.controls.providerId.touched">
              <ion-text color="danger" *ngIf="mealForm.controls.providerId.errors?.['required']">
                  Required Field
              </ion-text>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item class="input-item">
              <ion-input *ngIf="mealForm.controls.providerId.value === currentCamper.id" placeholder="What are you bringing?" [formControlName]="'name'"></ion-input>
              <ion-input *ngIf="mealForm.controls.providerId.value !== currentCamper.id" placeholder="What are they bringing?" [formControlName]="'name'"></ion-input>
              <ion-icon class="form-icon" slot="end" name="pizza-outline"></ion-icon>
            </ion-item>
            <div *ngIf="mealForm.controls.name.invalid && mealForm.controls.name.touched">
              <ion-text color="danger" *ngIf="mealForm.controls.name.errors?.['required']">
                  Required Field
              </ion-text>
            </div>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <ion-item style="min-width: 84vw;" class="bordered-item" lines="none">
                <ion-select *ngIf="mealForm.controls.providerId.value === currentCamper.id" cancelText="Cancel" okText="Done" [formControlName]="'mealDay'" placeholder="When are you serving it?">
                  <ion-select-option [value]="dontKnowText">I don't know</ion-select-option>
                  <ion-select-option *ngFor="let day of daysToPick" [value]="day">{{ day | date : 'EEEE, MMM d' }}</ion-select-option>
                </ion-select>
                <ion-select *ngIf="mealForm.controls.providerId.value !== currentCamper.id" cancelText="Cancel" okText="Done" [formControlName]="'mealDay'" placeholder="When are they serving it?">
                  <ion-select-option [value]="dontKnowText">I don't know</ion-select-option>
                  <ion-select-option *ngFor="let day of daysToPick" [value]="day">{{ day | date : 'EEEE, MMM d' }}</ion-select-option>
                </ion-select>
              <ion-icon slot="end" name="time-outline"></ion-icon>
            </ion-item>
            <div *ngIf="mealForm.controls.mealDay.invalid && mealForm.controls.mealDay.touched">
              <ion-text color="danger" *ngIf="mealForm.controls.mealDay.errors?.['required']">
                  Required Field
              </ion-text>
            </div>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <ion-item style="min-width: 84vw;" class="bordered-item" lines="none">
              <ion-select cancelText="Cancel" okText="Done" [formControlName]="'mealType'" placeholder="Which meal of the day is it for?">
                <ion-select-option *ngFor="let meal of mealsToPick" [value]="meal">{{ meal }}</ion-select-option>
              </ion-select>
              <ion-icon slot="end" name="fast-food-outline"></ion-icon>
            </ion-item>
            <div *ngIf="mealForm.controls.mealType.invalid && mealForm.controls.mealType.touched">
              <ion-text color="danger" *ngIf="mealForm.controls.mealType.errors?.['required']">
                  Required Field
              </ion-text>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col offset="1" size="auto" class="control-checkbox">
            <ion-checkbox [formControlName]="'requireRSVP'"></ion-checkbox>
          </ion-col>
          <ion-col>Require RSVP</ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <ion-item class="bordered-textarea-item" lines="none">
              <ion-label position="stacked">Notes</ion-label>
              <ion-textarea [formControlName]="'notes'"></ion-textarea>
            </ion-item>

          </ion-col>
        </ion-row>
      </form>
      
      
      <ion-row>
        <ion-col>
            <ion-button (click)="onSave($event)">Save</ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button (click)="onClose($event)">Cancel</ion-button>
        </ion-col>
      </ion-row>

      <!-- <div>
        <p class="text-notice">{{ mainText }}</p>
        <ion-button class="quantity-selection-button"><ion-icon slot="icon-only" name="remove-outline"></ion-icon></ion-button>
        <h2 class="quantity-selection-quantity">{{ quantity }} / {{ total }}</h2>
        <ion-button class="quantity-selection-button"><ion-icon slot="icon-only" name="add-outline"></ion-icon></ion-button>

          <ion-list>
            <ion-item [lines]="'none'">
              <ion-input inputmode="numeric" [(ngModel)]="quantity" placeholder="How many?" type="number" description="numeric"></ion-input>
            </ion-item>
          </ion-list>
  
          <ion-item-divider></ion-item-divider>
          <div class="action-btn-wrapper">
            <ion-button (click)="onSave($event)">Save</ion-button>
            <ion-button (click)="onClose($event)">Cancel</ion-button>
          </div>
      </div> -->
    </ion-card-content>
  </ion-card>
</div>
