<!-- <ion-app>
  <ion-router-outlet></ion-router-outlet>
</ion-app> -->



<ion-app>
  
  <ion-content>
    

    <ion-split-pane contentId="main-content">
      <ion-menu contentId="main-content" type="overlay">
        
          <ion-list id="inbox-list">
            
            <ion-list-header *ngIf="currentCamper"><p>Hi, {{ currentCamper.name }}!</p></ion-list-header>

            <ion-menu-toggle>
              <ion-item [routerDirection]="'root'" [routerLink]="'/home'">
                <ion-icon slot="start" src="../../assets/icons/home-tab.svg"></ion-icon>
                <ion-label>
                  Home
                </ion-label>
              </ion-item>
              <ion-item [routerDirection]="'root'" [routerLink]="'/trips'">
                <ion-icon slot="start" name="bonfire-sharp"></ion-icon>
                <ion-label>
                  Trips
                </ion-label>
              </ion-item>
              <ion-item [routerDirection]="'root'" [routerLink]="'/friends'">
                <ion-icon slot="start" name="people-circle"></ion-icon>
                <ion-label>
                  Friends
                </ion-label>
                <span *ngIf="hasFriendshipRequests" class="ion-text-end">
                  <ion-icon color="danger" name="alert-circle-outline"></ion-icon>
                </span>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle>
              <ion-item *ngIf="!currentCamper" [routerDirection]="'root'" [routerLink]="['/auth-flow/sign-in']">
                <ion-icon slot="start" [name]="'log-in-outline'"></ion-icon>
                <ion-label>
                  Sign in
                </ion-label>
              </ion-item>
              <ion-item *ngIf="currentCamper" (click)="signOut()">
                <ion-icon slot="start" [name]="'log-out-outline'"></ion-icon>
                <ion-label>
                  Sign out
                </ion-label>
              </ion-item>
              
              
            </ion-menu-toggle>
            <ion-item (click)="onHiddenFeatureButtonClick()">
              <ion-icon slot="start" [name]="'information-circle-outline'"></ion-icon>
              <ion-label>
                Updated: <p>{{ buildTimestamp| date: 'short' }}</p>
              </ion-label>
            </ion-item>
            <ion-menu-toggle>
              <ion-item *ngIf="currentCamper" (click)="allowNotifications()">
                <ion-icon slot="start" name="notifications-circle-outline"></ion-icon>
                <ion-label>
                  Turn on notifications
                </ion-label>
              </ion-item>
              <ion-item *ngIf="showConnect4Ever" [routerDirection]="'root'" [routerLink]="'/games'">
                <ion-icon slot="start" name="aperture-outline"></ion-icon>
                <ion-label>
                  Games
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>

          
        
      </ion-menu>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
  </ion-content>
</ion-app>

