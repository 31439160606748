import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { InvitationStatusTypes } from 'src/app/models/constants';
import { GroupItemDisplay } from 'src/app/models/group-item-display';
import { TripDisplay } from 'src/app/models/trip-display';
import { CamperEntity, CamperProfile, GroupProvision } from 'src/app/services/treelove-api.service';
import { TripService } from 'src/app/services/trip.service';



@Component({
  selector: 'app-group-item-selection-modal',
  templateUrl: './group-item-selection-modal.component.html',
  styleUrls: ['./group-item-selection-modal.component.scss'],
})
export class GroupItemSelectionModal implements OnInit {
  @Input() trip: TripDisplay;
  @Input() item: GroupItemDisplay;
  @Input() currentCamper: CamperProfile;
  @Input() isHostAction: boolean = false;
  total: number;
  campersToPIck: CamperProfile[];
  public quantitySelectionForm = new FormGroup({
    quanity: new FormControl(1, [ Validators.required ]),
    providerId: new FormControl('', [ Validators.required ]),
  });
  constructor(private _modalController: ModalController, private _tripService: TripService) { }

  ionViewDidEnter() {
    const modalCardHeight = document.querySelector('.modal-card-wrapper')?.clientHeight;
    const modalCardWidth = document.querySelector('.modal-card-wrapper')?.clientWidth;
    document.documentElement.style.setProperty('--current-modal-card-height', `${modalCardHeight}px`);
    document.documentElement.style.setProperty('--current-modal-card-width', `${modalCardWidth}px`);
    this.setupOptions();
  }

  setupOptions() {
    if(this.trip.currentCamperIsHost && this.isHostAction) {
      this.campersToPIck = this.trip.attendees.filter(x => x.status === InvitationStatusTypes.ACCEPTED)
        .filter(x => x.camper.id !== this.currentCamper.id)
        .map(x => x.camper);
    } else {
      this.quantitySelectionForm.controls.providerId.setValue(this.currentCamper.id);
      var quantityProvidedByCurrentCamper = this.item.currentCamperProviderProvision?.provisionedCount ?? 0;
      this.quantitySelectionForm.controls.quanity.setValue(quantityProvidedByCurrentCamper);
      this.total = this.item.requiredCount - this.item.totalProvisionsProvided + quantityProvidedByCurrentCamper;
    }
  }

  setQuantity() {
    var startingQuantity = 1;
    var quantityProvidedBySelectedCamper = 0;
    var selectedProvisionerProvision = this.item.provisions.find(x => x.providerId === this.quantitySelectionForm.controls.providerId.value);
    if(selectedProvisionerProvision) {
      quantityProvidedBySelectedCamper = selectedProvisionerProvision.provisionedCount;
      startingQuantity = selectedProvisionerProvision.provisionedCount;
    }

    var quantityProvidedBySelectedCamper = 
    this.total = this.item.requiredCount - this.item.totalProvisionsProvided + quantityProvidedBySelectedCamper;
    this.quantitySelectionForm.controls.quanity.setValue(startingQuantity);
  }

  ngOnInit() {}

  onClose(event) {
    this._modalController.dismiss(null);
  }


  onSave(event) {
    if(!this.quantitySelectionForm.valid) {
      this.quantitySelectionForm.markAllAsTouched();
    } else {
      var provision = new GroupProvision();
      provision.providerId = this.quantitySelectionForm.controls.providerId.value;
      provision.provisionedCount = this.quantitySelectionForm.controls.quanity.value;
      this._modalController.dismiss(provision);
    }
  }

  increaseQuantity() {
    var quantity = this.quantitySelectionForm.controls.quanity.value;
    // if(quantity < this.total) {
      quantity += 1;
      this.quantitySelectionForm.controls.quanity.setValue(quantity);
    // }
  }

  decreaseQuantity() {
    var quantity = this.quantitySelectionForm.controls.quanity.value;
    if(quantity > 0) {
      quantity -= 1;
    }
    this.quantitySelectionForm.controls.quanity.setValue(quantity);
  }

  getCamper(camperId: string) : CamperEntity {
    return this._tripService.getCamper(camperId);
  }

}
