import { Injectable } from '@angular/core';
import { Connect4EverGame, Connect4EverGameRequest, Connect4EverGameSaveRequest, Connect4EverGameResponse, GamesResponse, Request, TreeloveApiService, CamperProfile } from './treelove-api.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { Connect4EverGameDisplay } from '../models/connect-4-ever-game-display';
import { CamperWithTripsAndFriends } from '../models/camper';
import { AccountsService } from './accounts.service';

import { Storage } from '@ionic/storage-angular';
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root'
})
export class Connect4EverService {
  private _currentPlayer: CamperProfile;
  private _currentGameStorageKey = 'currentGame';
  private _currentGame: Connect4EverGameDisplay;
  currentGameSubject: BehaviorSubject<Connect4EverGame> = new BehaviorSubject<Connect4EverGame>(null);
  games: Connect4EverGame[];
  gamesSubject: BehaviorSubject<Connect4EverGame[]> = new BehaviorSubject<Connect4EverGame[]>(null);


  constructor(private _treeloveApiService: TreeloveApiService, private _storageService: StorageService,
      private _accountsService: AccountsService) {
    this._accountsService.currentCamperWithTripsAndFriendsSubject.subscribe((currentCamper: CamperWithTripsAndFriends) => {
      if (currentCamper?.camperProfile.id) {
        this._currentPlayer = currentCamper.camperProfile;
        this.loadGames();
        this.loadCurrentGameFromStorage();
      }
    });
    
    
   }
   public loadCurrentGameFromStorage() {
    this._storageService.get(this._currentGameStorageKey).then((gameId) => {
      if(gameId) {
        this.getAndSetGame(gameId);
      }
    });

   }

   public getAndSetGame(gameId: string) {
    if(this._currentGame?.id !== gameId) {
      this._currentGame = null;
      this.currentGameSubject.next(this._currentGame);
    }
    const request = new Connect4EverGameRequest();
    request.gameId = gameId;
    // request.treeloveId = this.currentCamper.treeloveId;
    const promise = new Promise<void>((resolve, reject) => {
      this._treeloveApiService.getConnect4EverGameById(request).subscribe((response: Connect4EverGameResponse) => {
        if(response.isSuccess) {
          this.setGame(response.game);
        }
        resolve();
      });
    });
    return promise;
  }

  private setGame(game: Connect4EverGame) {
    var currentPlayerId = this._currentPlayer?.id;
    var gameDisplay = new Connect4EverGameDisplay(game, currentPlayerId);
    this._currentGame = gameDisplay;
    this._storageService.set(this._currentGameStorageKey, this._currentGame.id);
    this.currentGameSubject.next(this._currentGame);
  }

   public loadGames() {
    //var promise = new Promise<Connect4EverGame>((resolve, reject) => {
      var request = new Request();
      this._treeloveApiService.getGames(request).subscribe((response: GamesResponse) => {
        if(response.isSuccess) {
          this.games = response.games;
          this.gamesSubject.next(this.games);
          // resolve(response.game)
        }
      });
    // });
    // return promise;
   }

   public createConnect4EverGame(game: Connect4EverGame) {
    var promise = new Promise<void>((resolve, reject) => {
      var request = new Connect4EverGameSaveRequest();
      request.game = game;
      this._treeloveApiService.createConnect4EverGame(request).subscribe((response: Connect4EverGameResponse) => {
        if(response.isSuccess) {
          this._currentGame = new Connect4EverGameDisplay(response.game, this._currentPlayer.id);
          this.currentGameSubject.next(this._currentGame);
          this.games.push(this._currentGame);
          this.gamesSubject.next(this.games);
          resolve();
        }
      });
    });
    return promise;
   }

   public setConnect4EverGame(game: Connect4EverGame) {
    var promise = new Promise<Connect4EverGame>((resolve, reject) => {
      var request = new Connect4EverGameSaveRequest();
      request.game = game;
      this._treeloveApiService.setConnect4EverGame(request).subscribe((response: Connect4EverGameResponse) => {
        if(response.isSuccess) {
          this._currentGame = new Connect4EverGameDisplay(response.game, this._currentPlayer.id);
          this.currentGameSubject.next(this._currentGame);
        }
      });
    });
    return promise;
   }

  //  public startNewConnect4EverGame() {
  //   this._currentGame = new Connect4EverGameDisplay(new Connect4EverGame(), this._currentPlayer.id);
  //   this._currentGame.resetGame();
    
  //   return this._currentGame;
  // }
}
