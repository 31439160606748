import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// import { CurrentUserDisplay } from '../models/current-user-display';

import { Request, Response, CamperProfile, TreeloveApiService, CamperWithTripsAndFriendsResponse, Trip, TripPreview, CamperProfileResponse, CamperDeletionRequest, CreateCamperProfileRequest, InviteRequest, NotificationTokenRequest } from './treelove-api.service';
import { CamperWithTripsAndFriends } from '../models/camper';
import { User } from 'firebase/auth';
import { AccountDeletion } from '../auth/auth.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  
  private _currentCamperWithTripsAndFriends = new CamperWithTripsAndFriends();
  
  public currentCamperWithTripsAndFriendsSubject: BehaviorSubject<CamperWithTripsAndFriends> = new BehaviorSubject<CamperWithTripsAndFriends>(null);

  
  constructor(private _treeloveApiService: TreeloveApiService, private  _storageService : StorageService) {

    // this._authService.currentAuthUserSubject.subscribe((user: User) => {
    //   if(user?.displayName && !this._currentCamperWithTripsAndFriends) {    
    //     this.preloadCamperProfile(user.displayName, user.photoURL);
    //   }
    // });

    // this._authService.firebaseAuthTokenSubject.subscribe((token: string) => {
    //   if(token) {
    //     this._loadAccounts();
    //   } else {
    //     this.unLoadAccounts();
    //   }
    // });
  }

  async createAccount(googleAuthId: string, username: string, email: string) {
    const request = new CreateCamperProfileRequest();
    var inviteResponse = await this._storageService.getInvite();
    request.inviterId = inviteResponse.inviterId;
    request.tripId = inviteResponse.tripId;
    request.googleAuthUserId = googleAuthId;
    request.name = username;
    request.email = email;
    const promise = new Promise((resolve, reqject) => {
      this._treeloveApiService.createCamper(request).subscribe((response: CamperWithTripsAndFriendsResponse) => {
        if(response.isSuccess) {
          if(inviteResponse.inviterId) {
            this._storageService.clearInvite();
          }
          var currentCamperWithTripsAndFriends = new CamperWithTripsAndFriends();
          currentCamperWithTripsAndFriends.camperProfile = response.camperProfile;
          currentCamperWithTripsAndFriends.friends = response.friends ?? [];
          currentCamperWithTripsAndFriends.friendshipRequests = response.friendshipRequests ?? [];
          currentCamperWithTripsAndFriends.trips = response.trips ?? [];
          this._setCurrentUser(currentCamperWithTripsAndFriends);
          resolve(true);
        }
      });
    });
    return promise;
  }

  async loadAccount() {
    const request = new InviteRequest();
    var inviteResponse = await this._storageService.getInvite();
    request.inviterId = inviteResponse.inviterId;
    request.tripId = inviteResponse.tripId;

      this._treeloveApiService.getCamperWithTripsAndFriends(request).subscribe((response: CamperWithTripsAndFriendsResponse) => {
        if (response.isSuccess) {
          if(inviteResponse.inviterId) {
            this._storageService.clearInvite();
          }
          var currentCamperWithTripsAndFriends = new CamperWithTripsAndFriends();
          currentCamperWithTripsAndFriends.camperProfile = response.camperProfile;
          currentCamperWithTripsAndFriends.friends = response.friends ?? [];
          currentCamperWithTripsAndFriends.friendshipRequests = response.friendshipRequests ?? [];
          currentCamperWithTripsAndFriends.trips = response.trips ?? [];
          this._setCurrentUser(currentCamperWithTripsAndFriends);

          // this.authService.setToken(response.token);
          // this._setCurrentUser(response.consumerProfile, response.vendorProfile);
          
        }
        else {
          // this.authService.revokeToken();
        }
      });
    // });
    // return promise;
  }

  private _updateEmail() {
    const request = new Request();

    const promise = new Promise((resolve, reject) => {
      this._treeloveApiService.updateEmail(request).subscribe((response: CamperProfileResponse) => {
        if (response.isSuccess) {
          // this.authService.setToken(response.token);
          // this._setCurrentUser(response.consumerProfile, response.vendorProfile);
          
          resolve(true);
        }
        else {
          // this.authService.revokeToken();
          reject(false);
        }
      });
    });
    return promise;
  }
  updateUsername(username: string) {
    // this._currentUser.consumer.name = username;
    // this._setCurrentUser(this._currentUser.consumer, this._currentUser.vendor)
    
    // this._preacherPalApiService.updateUsername(new Request());
  }

  public addTripToCamperWithTripsAndFriends(trip: Trip) {
    this._currentCamperWithTripsAndFriends.trips = this._currentCamperWithTripsAndFriends.trips?.filter(x => x.tripId !== trip.id) ?? [];
    var tripPreview = new TripPreview({
      tripId: trip.id,
      name: trip.name,
      bannerPhotoURL: trip.bannerPhotoURL,
      startDateUtc: trip.startDateUtc,
      endDateUtc: trip.endDateUtc,
      notes: trip.notes
    });
    this._currentCamperWithTripsAndFriends.trips.push(tripPreview);
    this.currentCamperWithTripsAndFriendsSubject.next(this._currentCamperWithTripsAndFriends);
  }

  public removeTripFromCamperWithTripsAndFriends(tripId: string) {
    this._currentCamperWithTripsAndFriends.trips = this._currentCamperWithTripsAndFriends.trips.filter(x => x.tripId !== tripId);
    this.currentCamperWithTripsAndFriendsSubject.next(this._currentCamperWithTripsAndFriends);
  }

  preloadCamperProfile(firebaseUser: User) {  
    if(firebaseUser?.displayName) {      
      var camperWithTripsAndFriends = new CamperWithTripsAndFriends();
      camperWithTripsAndFriends.camperProfile = new CamperProfile();
      camperWithTripsAndFriends.camperProfile.name = firebaseUser.displayName;
      camperWithTripsAndFriends.camperProfile.avatarSourceURL = firebaseUser.photoURL;    
      this._setCurrentUser(camperWithTripsAndFriends);
    }
  }

  private _setCurrentUser(camperWithTripsAndFriends: CamperWithTripsAndFriends) {    
    // preserve consumer name from Firebase User
    if(this._currentCamperWithTripsAndFriends?.camperProfile) {
      camperWithTripsAndFriends.camperProfile.name = this._currentCamperWithTripsAndFriends?.camperProfile.name;
    }

    this._currentCamperWithTripsAndFriends = camperWithTripsAndFriends;
    this.currentCamperWithTripsAndFriendsSubject.next(this._currentCamperWithTripsAndFriends);
  }

  public unLoadAccounts() {
    this._currentCamperWithTripsAndFriends = null;
    this.currentCamperWithTripsAndFriendsSubject.next(null);
  }

  deleteAuthUserInit(accountDeletion: AccountDeletion) {
    const request = new CamperDeletionRequest();
    request.token = accountDeletion.token;
    request.camperId = accountDeletion.userId;
    const promise = new Promise((resolve, reqject) => {
      this._treeloveApiService.deleteCamperInit(request).subscribe();
    });
  }


  deleteAuthUserFinal(accountDeletion: AccountDeletion) {
    const request = new CamperDeletionRequest();
    request.token = accountDeletion.token;
    request.camperId = accountDeletion.userId;
    const promise = new Promise((resolve, reqject) => {
      this._treeloveApiService.deleteCamperFinal(request).subscribe();
    });
  }

  deleteAccountRollBack(accountDeletion: AccountDeletion) {
    const request = new CamperDeletionRequest();
    request.token = accountDeletion.token;
    request.camperId = accountDeletion.userId;
    const promise = new Promise((resolve, reqject) => {
      this._treeloveApiService.deleteCamperRollBack(request).subscribe();
    });
  }

  

}
