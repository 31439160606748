import { Injectable, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AccountsService } from './accounts.service';
import { Router } from '@angular/router';
import { CamperWithTripsAndFriends } from '../models/camper';
import { NotificationTokenRequest, TreeloveApiService, Response } from './treelove-api.service';
import { Messaging, getToken, onMessage } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { getMessaging } from 'firebase/messaging';
import { environment } from 'src/environments/environment';
import { Connect4EverService } from './connect-4-ever.service';
import { StorageService } from './storage.service';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService implements OnInit {
  private _currentUser: CamperWithTripsAndFriends;
  private _hasRegistered: boolean;
  private token: string;

  private readonly _message = new BehaviorSubject<unknown | undefined>(undefined);

  title = 'fcm-angular-demo';
  message$ = this._message.asObservable();

  messaging: Messaging;
  promises: Promise<any>[] = [];
  private _previousPermisionStorageKey = "previousPermisionStorageKey";
  private _hasPreviouslyGrantedNotificationPermissions: boolean = false;

  constructor(private _accountsService: AccountsService, private _router: Router, private _storageService: StorageService,
              private _treeloveApiService: TreeloveApiService, private _connect4EverGameService: Connect4EverService,
              private _toastController: ToastController) {
    
    this._storageService.get(this._previousPermisionStorageKey).then((hasPreviouslyGrantedNotificationPermissions: boolean) => {
      this._hasPreviouslyGrantedNotificationPermissions = hasPreviouslyGrantedNotificationPermissions;
      if(this._hasPreviouslyGrantedNotificationPermissions) {
        this.requestPermission();
      }
    });
    this.messaging = getMessaging();
    

    var promise = new Promise<void>((resolve, reject) => {
      this._accountsService.currentCamperWithTripsAndFriendsSubject.subscribe((currentUser) => {
        if(currentUser) {
          resolve();
        }
        if (currentUser && !this._hasRegistered) {
          if ('serviceWorker' in navigator && !Capacitor.isNativePlatform()) {
            navigator.serviceWorker.register('/firebase-messaging-sw.js')
              .then((registration) => {
                console.log('Service Worker registered with scope:', registration.scope);
              }).catch((err) => {
                console.log('Service Worker registration failed:', err);
              });
          }
          
          this.listen();
        }
  
        navigator.serviceWorker.addEventListener('message', (event) => {
          if (event.data && event.data.data.type === 'FCM_MESSAGE') {
            this.handleFCMMessage(event.data);
          } else if (event.data && event.data.type === 'OPEN_GAME') {
            this.playGame(event.data.gameId);
          }
        });
      });
    });
    this.promises.push(promise);
    
  }

  handleFCMMessage(payload: any) {
    console.log('Handling FCM message in main app:', payload);
    if (payload.data.gameId) {
      this.playGame(payload.data.gameId);
    }
  }

  playGame(gameId: string) {
    this._connect4EverGameService.getAndSetGame(gameId);
    this._router.navigateByUrl(`/games/connect4ever`);
  }

  getToken() {
    return this.token;
  }

  async requestPermission() {
    console.log('Requesting permission...');
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        // console.log('Notification permission granted.');
        const toast = await this._toastController.create({
          message: 'Notification permission granted.',
          duration: 1500
        });
        toast.present();
        const token = await getToken(this.messaging, { vapidKey: environment.firebase.vapidKey });
        if (token) {
          this._hasRegistered = true;
          console.log('FCM Token:', token);
          this.token = token;
          this._storageService.set(this._previousPermisionStorageKey, true);
          Promise.all(this.promises).then(() => {
            this.updateCamperNotificationToken(token);
          });
          
          // alert(token)
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      } else {
        console.log('Unable to get permission to notify.');
      }
    } catch (error) {
      console.error('An error occurred while retrieving token. ', error);
    }
  };

  listen() {
    onMessage(this.messaging, (payload) => {
      console.log(payload.data.gameId);
      if (payload.data.gameId) {
        this.playGame(payload.data.gameId);
      }
      console.log('Message received. ', payload);
      // Handle the message as needed
      // alert('New message received: ' + payload.notification.title);
    });
  }

  public updateCamperNotificationToken(token: string) {
    const request = new NotificationTokenRequest();
    // request.treeloveId = this.currentCamper.treeloveId;
    request.notificationToken = token;
    const promise = new Promise<void>((resolve, reject) => {
      this._treeloveApiService.updateCamperNotificationToken(request).subscribe((response: Response) => {
        resolve();
      });
    });
    return promise;
  }

  ngOnInit() {
    // Request permission to receive notifications
  }
}
