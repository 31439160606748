import { Injectable } from '@angular/core';
import { InventoryCatalogItem, Request, TreeloveApiService } from './treelove-api.service';
import { BehaviorSubject } from 'rxjs';
import { InventoryCatalogItemDisplay } from '../models/inventory-catalog-item-display';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  inventoryCatalogItemDisplays: InventoryCatalogItemDisplay[];
  public inventoryCatalogItemDisplaysSubject: BehaviorSubject<InventoryCatalogItemDisplay[]> = new BehaviorSubject<InventoryCatalogItemDisplay[]>(null);


  constructor(private _treeloveAPIService: TreeloveApiService) {
    this.loadAllInventoryCatalogItems();
  }

  public loadAllInventoryCatalogItems() {
      var request = new Request();
      this._treeloveAPIService.getAllInventoryCatalogItems(request).subscribe((response) => {
        if(response.isSuccess) {
          this.inventoryCatalogItemDisplays = response.inventoryCatalogItems.map(x => new InventoryCatalogItemDisplay(x));
          this.inventoryCatalogItemDisplaysSubject.next(this.inventoryCatalogItemDisplays);
        } 
      });
  
   
  }

  
}
