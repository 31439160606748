import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { CamperProfile, Connect4EverGame, GeoCoordinates, Trip, TripPreview } from 'src/app/services/treelove-api.service';
import { environment } from 'src/environments/environment';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import * as mapboxgl from 'mapbox-gl';
import { Connect4EverGameDisplay } from 'src/app/models/connect-4-ever-game-display';






@Component({
  selector: 'app-game-creation-modal',
  templateUrl: './game-creation-modal.component.html',
  styleUrls: ['./game-creation-modal.component.scss'],
})
export class GameCreationModalComponent implements OnInit {
  @Input() game: Connect4EverGameDisplay;
  @Input() friends: CamperProfile[];
  public newGameForm = new FormGroup({
    opponentId: new FormControl('', [ Validators.required ]),
    rows: new FormControl(6, [ Validators.required ]),
    cols: new FormControl(7, [ Validators.required ]),
    points: new FormControl(11, [ Validators.required ]),
    winByThreshold: new FormControl(2, [ Validators.required ]),
    // shouldConnect4ever: new FormControl(true, [ Validators.required ])
  });


  constructor(private _modalController: ModalController) { 
   
    
  }

  ionViewDidEnter() {
    const modalCardHeight = document.querySelector('.modal-card-wrapper')?.clientHeight;
    const modalCardWidth = document.querySelector('.modal-card-wrapper')?.clientWidth;
    document.documentElement.style.setProperty('--current-modal-card-height', `${modalCardHeight}px`);
    document.documentElement.style.setProperty('--current-modal-card-width', `${modalCardWidth}px`);
  }


  ngOnInit() {
    
  }

  onClose() {
    this._modalController.dismiss(null);
  }

  onSave() {
    if(this.newGameForm.invalid){
      this.newGameForm.markAllAsTouched();
      return;
    } else {
      this.game.playerTwoId = this.newGameForm.controls.opponentId.value;
      this.game.totalRows = this.newGameForm.controls.rows.value;
      this.game.totalCols = this.newGameForm.controls.cols.value;
      this.game.resetGame();
      // this.game.shouldConnect4Ever = this.newGameForm.controls.shouldConnect4ever.value;
      
      this._modalController.dismiss(this.game);
    }
  }
  
  increaseRows() {
    var quantity = this.newGameForm.controls.rows.value;
    // if(quantity < this.total) {
      quantity += 1;
      this.newGameForm.controls.rows.setValue(quantity);
    // }
  }

  decreaseRows() {
    var quantity = this.newGameForm.controls.rows.value;
    if(quantity > 4) {
      quantity -= 1;
    }
    this.newGameForm.controls.rows.setValue(quantity);
  }

  increaseCols() {
    var quantity = this.newGameForm.controls.cols.value;
    // if(quantity < this.total) {
      quantity += 1;
      this.newGameForm.controls.cols.setValue(quantity);
    // }
  }

  decreaseCols() {
    var quantity = this.newGameForm.controls.cols.value;
    if(quantity > 4) {
      quantity -= 1;
    }
    this.newGameForm.controls.cols.setValue(quantity);
  }

  increasePoints() {
    var quantity = this.newGameForm.controls.points.value;
    // if(quantity < this.total) {
      quantity += 1;
      this.newGameForm.controls.points.setValue(quantity);
    // }
  }

  decreasePoints() {
    var quantity = this.newGameForm.controls.points.value;
    if(quantity > 1) {
      quantity -= 1;
    }
    this.newGameForm.controls.points.setValue(quantity);
  }

  increaseWinByThreshold() {
    var quantity = this.newGameForm.controls.winByThreshold.value;
    // if(quantity < this.total) {
      quantity += 1;
      this.newGameForm.controls.winByThreshold.setValue(quantity);
    // }
  }

  decreaseWinByThreshold() {
    var quantity = this.newGameForm.controls.winByThreshold.value;
    if(quantity > 1) {
      quantity -= 1;
    }
    this.newGameForm.controls.winByThreshold.setValue(quantity);
  }



}
