import { Injectable } from '@angular/core';
import { TreeloveApiService, Trip, PersonalItem, CamperProfile, TripRequest, SaveTripRequest, TripResponse, TimeframeVote, TimeframeVoteRequest, Response, CamperEntity, Host, Attendee, UpdateAttendeesRequest, TripLocation, PersonalItemStatusRequest, PersonalItemStatus, PersonalItemResponse, PersonalProvision, PersonalProvisionRequest, PersonalProvisionsRequest, GroupProvision, GroupProvisionRequest, GroupItemResponse, SharedMeal, SharedMealRequest, SharedMealResponse, Carpool, CarpoolRequest, CarpoolRideWithRequest, CarpoolsResponse, PersonalItemRequest, GroupItemRequest, Request, GroupItem, SharedMealParticpantRequest } from './treelove-api.service';
import { BehaviorSubject } from 'rxjs';
import { CamperService } from './camper.service';
import { Storage } from '@ionic/storage-angular';
import { Router } from '@angular/router';
import { AccountsService } from './accounts.service';
import { CamperWithTripsAndFriends } from '../models/camper';
import { HostTitleTypes, InvitationStatusTypes } from '../models/constants';
import { TripDisplay } from '../models/trip-display';
import { AuthService } from '../auth/auth.service';
import { PersonalItemDisplay } from '../models/personal-item-display';
import { GroupItemDisplay } from '../models/group-item-display';
import { SharedMealDisplay } from '../models/shared-meal-display';
import { CarpoolDisplay } from '../models/carpool-display';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class TripService {
  private currentCamperWithTripsAndFriends: CamperWithTripsAndFriends;
  private _currentTrip: TripDisplay;
  private _currentTripStorageKey = 'currentTrip';
  public currentTripDisplaySubject: BehaviorSubject<TripDisplay> = new BehaviorSubject<TripDisplay>(null);

  constructor(private _treeloveAPIService: TreeloveApiService, private _accountsService: AccountsService,
    private router: Router, private _storageService: StorageService, private _authService: AuthService) {
      this._authService.isAuthenticated().then(() => {
        this._accountsService.currentCamperWithTripsAndFriendsSubject.subscribe((camperWithTripsAndFriends: CamperWithTripsAndFriends) => {
          if (camperWithTripsAndFriends) {
            this.currentCamperWithTripsAndFriends = camperWithTripsAndFriends;
          }
          if (camperWithTripsAndFriends?.camperProfile.id) {
            this.loadCurrentTripFromStorage();
          }
        });
      });
   }

   public loadCurrentTripFromStorage() {
    this._storageService.get(this._currentTripStorageKey).then((tripId) => {
      if (tripId) {
        this.getAndSetTrip(tripId);
      }
    });
   }
  
  getCamper(camperId: string) : CamperEntity {
    if(camperId) {
      var attendee =  this._currentTrip.attendees?.find(x => x.camper.id === camperId);
      return attendee?.camper;
    }
    return null;
  }

  public startNewTrip() {
    var promise = new Promise<TripDisplay>((resolve, reject) => {
      this._currentTrip = new TripDisplay();
      this._currentTrip.personalItems = [];
      this._currentTrip.groupItems = [];
      this._currentTrip.sharedMeals = [];
      this._currentTrip.carpools = [];
      this._currentTrip.location = new TripLocation();
      var host = new Host();
      host.camper = this.currentCamperWithTripsAndFriends.camperProfile;
      host.title = HostTitleTypes.HOST;
      this._currentTrip.hosts  = [ host ];
      var attendee = new Attendee;
      attendee.camper = this.currentCamperWithTripsAndFriends.camperProfile;
      attendee.status = "Accepted"
      this._currentTrip.setAttendees([ attendee ]);
      // this.currentTripSubject.next(this._currentTrip);
      resolve(this._currentTrip);
    });
    
    return promise;
  }

  public saveTrip(tripToSave: Trip) {
    const request = new SaveTripRequest();
    request.trip = tripToSave;
    // request.treeloveId = this.currentCamper.treeloveId;
    const promise = new Promise<void>((resolve, reject) => {
        this._treeloveAPIService.saveTrip(request).subscribe((response: TripResponse) => {
          this.setTrip(response.trip);
          this._accountsService.addTripToCamperWithTripsAndFriends(this._currentTrip);
          resolve();
        });
    });
    return promise;
  }

  public createTrip(tripToSave: Trip, bannerPhotoImageSrc: string) {
    const request = new SaveTripRequest();
    request.trip = tripToSave;
    request.bannerPhotoImageSrc = bannerPhotoImageSrc;
    // request.treeloveId = this.currentCamper.treeloveId;
    const promise = new Promise<void>((resolve, reject) => {
      this._treeloveAPIService.createTrip(request).subscribe((response: TripResponse) => {
        this.setTrip(response.trip)
        this._accountsService.addTripToCamperWithTripsAndFriends(this._currentTrip);
        resolve();
      });
      
    });
    return promise;
  }


  

  public getAndSetTrip(tripId: string) {
    if(this._currentTrip?.id !== tripId) {
      this._currentTrip = null;
    }
    const request = new TripRequest();
    request.tripId = tripId;
    // request.treeloveId = this.currentCamper.treeloveId;
    const promise = new Promise<void>((resolve, reject) => {
      this._treeloveAPIService.getTrip(request).subscribe((response: TripResponse) => {
        if(response.isSuccess) {
          this.setTrip(response.trip);
        }
        resolve();
      });
    });
    return promise;
  }

  private setTrip(tripToSet: Trip) {
    var currentCamperId = this.currentCamperWithTripsAndFriends?.camperProfile?.id;
    var trip = new TripDisplay(tripToSet, currentCamperId);
    this._currentTrip = trip;
    this._storageService.set(this._currentTripStorageKey, this._currentTrip.id);
    this.currentTripDisplaySubject.next(this._currentTrip);
  }

  public clearTrip() {
    this._currentTrip = null;
    this._storageService.remove(this._currentTripStorageKey)
    this.currentTripDisplaySubject.next(null);
  }

  // public createInventoryItem(inventoryItemToSave: PersonalItem, provisionerId: string = null) {
  //   if(this._currentTrip.personalItems === null) {
  //     this._currentTrip.personalItems = [];
  //   }
    
  //   const request = new InventoryItemRequest ({ inventoryItem: inventoryItemToSave, provisionerId: provisionerId });
    
  //   // // const promise = new Promise<void>((resolve, reject) => {
  //   // //     this._treeloveAPIService.createInventoryItem(request).subscribe((response: InventoryItemResponse) => {
  //   // //       if(response.isSuccess) {
  //   // //         this._currentTrip.inventory.push(response.inventoryItem);
  //   // //         this.currentTripSubject.next(this._currentTrip);
  //   // //         resolve();
  //   // //       } else {
  //   // //         reject();
  //   // //       }
  //   // //     });
  //   // // });
  //   // return promise;
  // }

  public createSharedMeal(sharedMeal: SharedMeal) {
    var promise = new Promise<void>((resolve, reject) => {
      var request = new SharedMealRequest();
      request.sharedMeal = sharedMeal;
      this._treeloveAPIService.createSharedMeal(request).subscribe((response: SharedMealResponse) => {
        if(response.isSuccess) {
          this._currentTrip.sharedMeals.push(new SharedMealDisplay(response.sharedMeal, this.currentCamperWithTripsAndFriends.camperProfile.id));
          this.currentTripDisplaySubject.next(this._currentTrip);
          resolve();
        }
      });
    });
    return promise;
  }

  public addRsvpForSharedMeal(sharedMeal: SharedMeal, particpantId: string) {
    var promise = new Promise<void>((resolve, reject) => {
      var request = new SharedMealParticpantRequest();
      request.sharedMeal = sharedMeal;
      request.particpantId = particpantId;
      this._treeloveAPIService.addRsvpForSharedMeal(request).subscribe((response: SharedMealResponse) => {
        if(response.isSuccess) {
          this._currentTrip.sharedMeals = this._currentTrip.sharedMeals.filter(x => x.id !== sharedMeal.id);
          this._currentTrip.sharedMeals.push(new SharedMealDisplay(response.sharedMeal, this.currentCamperWithTripsAndFriends.camperProfile.id));
          this.currentTripDisplaySubject.next(this._currentTrip);
          resolve();
        }
      });
    });
    return promise;
  }

  public removeRsvpForSharedMeal(sharedMeal: SharedMeal, particpantId: string) {
    var promise = new Promise<void>((resolve, reject) => {
      var request = new SharedMealParticpantRequest();
      request.sharedMeal = sharedMeal;
      request.particpantId = particpantId;
      this._treeloveAPIService.removeRsvpForSharedMeal(request).subscribe((response: SharedMealResponse) => {
        if(response.isSuccess) {
          this._currentTrip.sharedMeals = this._currentTrip.sharedMeals.filter(x => x.id !== sharedMeal.id);
          this._currentTrip.sharedMeals.push(new SharedMealDisplay(response.sharedMeal, this.currentCamperWithTripsAndFriends.camperProfile.id));
          this.currentTripDisplaySubject.next(this._currentTrip);
          resolve();
        }
      });
    });
    return promise;
  }

  public removeSharedMeal(sharedMeal: SharedMeal) {
    var promise = new Promise<void>((resolve, reject) => {
      var request = new SharedMealRequest();
      request.sharedMeal = sharedMeal;
      this._treeloveAPIService.removeSharedMeal(request).subscribe((response: Response) => {
        if(response.isSuccess) {
          this._currentTrip.sharedMeals = this._currentTrip.sharedMeals.filter(x => x.id !== sharedMeal.id);
          this.currentTripDisplaySubject.next(this._currentTrip);
          resolve();
        }
      });
    });
    return promise;
  }

  public rsvpForSharedMeal(sharedMeal: SharedMeal) {
    var promise = new Promise<void>((resolve, reject) => {
      var request = new SharedMealRequest();
      request.sharedMeal = sharedMeal;
      this._treeloveAPIService.removeSharedMeal(request).subscribe((response: Response) => {
        if(response.isSuccess) {
          this._currentTrip.sharedMeals = this._currentTrip.sharedMeals.filter(x => x.id !== sharedMeal.id);
          this.currentTripDisplaySubject.next(this._currentTrip);
          resolve();
        }
      });
    });
    return promise;
  }

  public createCarpool(carpool: Carpool) {
    var promise = new Promise<void>((resolve, reject) => {
      var request = new CarpoolRequest();
      request.carpool = carpool;
      this._treeloveAPIService.createCarpool(request).subscribe((response: CarpoolsResponse) => {
        if(response.isSuccess) {
          this._currentTrip.carpools = response.carpools.map(x => new CarpoolDisplay(x, this.currentCamperWithTripsAndFriends.camperProfile.id));
          this.currentTripDisplaySubject.next(this._currentTrip);
          resolve();
        }
      });
    });
    return promise;
  }

  public addRsvpForCarpool(carpool: Carpool, camperRsvpId: string) {
    var promise = new Promise<void>((resolve, reject) => {
      var request = new CarpoolRideWithRequest();
      request.carpool = carpool;
      request.camperIdToAddToCarpool = camperRsvpId;

      this._treeloveAPIService.addRsvpForCarpool(request).subscribe((response: CarpoolsResponse) => {
        if(response.isSuccess) {
          this._currentTrip.carpools = response.carpools;
          this.currentTripDisplaySubject.next(this._currentTrip);
          resolve();
        }
      });
    });
    return promise;
  }

  public removeRsvpForCarpool(carpool: Carpool, camperRsvpId: string) {
    var promise = new Promise<void>((resolve, reject) => {
      var request = new CarpoolRideWithRequest();
      request.carpool = carpool;
      request.camperIdToAddToCarpool = camperRsvpId;

      this._treeloveAPIService.removeRsvpForCarpool(request).subscribe((response: CarpoolsResponse) => {
        if(response.isSuccess) {
          this._currentTrip.carpools = response.carpools;
          this.currentTripDisplaySubject.next(this._currentTrip);
          resolve();
        }
      });
    });
    return promise;
  }

  public removeCarpool(carpool: Carpool) {
    var promise = new Promise<void>((resolve, reject) => {
      var request = new CarpoolRequest();
      request.carpool = carpool;
      this._treeloveAPIService.removeCarpool(request).subscribe((response: Response) => {
        if(response.isSuccess) {
          this._currentTrip.carpools = this._currentTrip.carpools.filter(x => x.id !== carpool.id);
          this.currentTripDisplaySubject.next(this._currentTrip);
          resolve();
        }
      });
    });
    return promise;
  }



  public createPersonalItem(item: PersonalItem) {
    const request = new PersonalItemRequest();
    request.personalItem = item;
    const promise = new Promise<void>((resolve, reject) => {
        this._treeloveAPIService.createPersonalItem(request).subscribe((response: PersonalItemResponse) => {
          if(response.isSuccess) {
            this._currentTrip.personalItems.push(new PersonalItemDisplay(response.personalItem, this.currentCamperWithTripsAndFriends.camperProfile.id));
            this.currentTripDisplaySubject.next(this._currentTrip);
            resolve();
          } else 
          {
            reject();
          }
        });
    });
    return promise;
  }

  public setPersonalItemProvision(itemId: string, provision: PersonalProvision) {
    const request = new PersonalProvisionRequest();
    request.itemId = itemId;
    request.provision = provision;
    const promise = new Promise<PersonalItemDisplay>((resolve, reject) => {
        this._treeloveAPIService.setPersonalItemProvision(request).subscribe((response: PersonalItemResponse) => {
          if(response.isSuccess) {
            // this.getAndSetTrip(this._currentTrip.id)
            resolve(new PersonalItemDisplay(response.personalItem, this.currentCamperWithTripsAndFriends.camperProfile.id));
          } else 
          {
            reject();
          }
        });
    });
    return promise;
  }

  public createGroupItem(item: GroupItem) {
    const request = new GroupItemRequest();
    request.groupItem = item;
    const promise = new Promise<void>((resolve, reject) => {
        this._treeloveAPIService.createGroupItem(request).subscribe((response: GroupItemResponse) => {
          if(response.isSuccess) {
            this._currentTrip.groupItems.push(new GroupItemDisplay(response.groupItem, this.currentCamperWithTripsAndFriends.camperProfile.id));
            this.currentTripDisplaySubject.next(this._currentTrip);
            resolve();
          } else 
          {
            reject();
          }
        });
    });
    return promise;
  }

  public setGroupItemProvision(itemId: string, provision: GroupProvision) {
    const request = new GroupProvisionRequest();
    request.itemId = itemId;
    request.provision = provision;
    const promise = new Promise<GroupItemDisplay>((resolve, reject) => {
        this._treeloveAPIService.setGroupItemProvision(request).subscribe((response: GroupItemResponse) => {
          if(response.isSuccess) {
            // this.getAndSetTrip(this._currentTrip.id)
            resolve(new GroupItemDisplay(response.groupItem, this.currentCamperWithTripsAndFriends.camperProfile.id));
          } else 
          {
            reject();
          }
        });
    });
    return promise;
  }
  
  public removeGroupItem(item: GroupItem) {
    const request = new GroupItemRequest();
    request.groupItem = item;
    const promise = new Promise<void>((resolve, reject) => {
        this._treeloveAPIService.removeGroupItem(request).subscribe((response: GroupItemResponse) => {
          if(response.isSuccess) {
            this._currentTrip.groupItems = this._currentTrip.groupItems.filter(x => x.id !== item.id);
            this.currentTripDisplaySubject.next(this._currentTrip);
            resolve();
          } else 
          {
            reject();
          }
        });
    });
    return promise;
  }

  public removeProvisionsFromPersonalItem(itemId: string, provisions: PersonalProvision[]) {
    const request = new PersonalProvisionsRequest();
    request.itemId = itemId;
    request.provisions= provisions;
    const promise = new Promise<PersonalItemDisplay>((resolve, reject) => {
        this._treeloveAPIService.removeProvisionsFromPersonalItem(request).subscribe((response: PersonalItemResponse) => {
          if(response.isSuccess) {
            // this.getAndSetTrip(this._currentTrip.id)
            resolve(new PersonalItemDisplay(response.personalItem, this.currentCamperWithTripsAndFriends.camperProfile.id));
          } else 
          {
            reject();
          }
        });
    });
    return promise;
  }

  public removeProvisionsFromGroupItem(itemId: string, provision: GroupProvision) {
    const request = new GroupProvisionRequest();
    request.itemId = itemId;
    request.provision = provision;
    const promise = new Promise<GroupItemDisplay>((resolve, reject) => {
        this._treeloveAPIService.removeProvisionFromGroupItem(request).subscribe((response: GroupItemResponse) => {
          if(response.isSuccess) {
            // this.getAndSetTrip(this._currentTrip.id)
            resolve(new GroupItemDisplay(response.groupItem, this.currentCamperWithTripsAndFriends.camperProfile.id));
          
          } else 
          {
            reject();
          }
        });
    });
    return promise;
  }

  public removePersonalItem(item: PersonalItem) {
    const request = new PersonalItemRequest();
    request.personalItem = item;
    const promise = new Promise<void>((resolve, reject) => {
        this._treeloveAPIService.removePersonalItem(request).subscribe((response: PersonalItemResponse) => {
          if(response.isSuccess) {
            this._currentTrip.personalItems = this._currentTrip.personalItems.filter(x => x.id !== item.id);
            this.currentTripDisplaySubject.next(this._currentTrip);
            resolve();
          } else 
          {
            reject();
          }
        });
    });
    return promise;
  }



  public setPersonalItemStatus(itemId: string, camperStatus: PersonalItemStatus) {
    const request = new PersonalItemStatusRequest();
    request.itemId = itemId;
    request.personalItemStatus = camperStatus;
    const promise = new Promise<PersonalItemDisplay>((resolve, reject) => {
        this._treeloveAPIService.setPersonalItemStatus(request).subscribe((response: PersonalItemResponse) => {
          if(response.isSuccess) {
            // this.getAndSetTrip(this._currentTrip.id)
            resolve(new PersonalItemDisplay(response.personalItem, this.currentCamperWithTripsAndFriends.camperProfile.id));
          } else 
          {
            reject();
          }
        });
    });
    return promise;
  }

  // public removeItem(item: PersonalItem) {
  //   const request = new InventoryItemRemoveRequest();
  //   request.inventoryItemId = item.id;
  //   const promise = new Promise<void>((resolve, reject) => {
  //       this._treeloveAPIService.removeInventoryItem(request).subscribe((response: Response) => {
  //         if(response.isSuccess) {
  //           this.getAndSetTrip(this._currentTrip.id)
  //           resolve();
  //         } else 
  //         {
  //           reject();
  //         }
  //       });
  //   });
  //   return promise;
  // }

  public voteForTimeframe(timeframeVote: TimeframeVote) {
    const request = new TimeframeVoteRequest();
    request.tripId = this._currentTrip.id;
    request.timeframeVote = timeframeVote;
    // request.treeloveId = this.currentCamper.treeloveId;
    const promise = new Promise<void>((resolve, reject) => {
        this._treeloveAPIService.voteForTimeframe(request).subscribe((response: TripResponse) => {
          if(response.isSuccess) {
            this.setTrip(response.trip);
          }
          resolve();
        });
    });
    return promise;
  }

  public removeVoteForTimeframe(timeframeVote: TimeframeVote) {
    const request = new TimeframeVoteRequest();
    request.tripId = this._currentTrip.id;
    request.timeframeVote = timeframeVote;
    // request.treeloveId = this.currentCamper.treeloveId;
    const promise = new Promise<void>((resolve, reject) => {
        this._treeloveAPIService.removeVoteForTimeframe(request).subscribe((response: TripResponse) => {
          if(response.isSuccess) {
            this.setTrip(response.trip);
          }
          resolve();
        });
    });
    return promise;
  }

  public updateAttendees(attendees: Attendee[]) {
    var tripId = this._currentTrip.id;
    var promise = new Promise((resolve, reject) => {
      var request = new UpdateAttendeesRequest({ 
        tripId: tripId,
        attendees: attendees
      });
      this._treeloveAPIService.updateAttendees(request).subscribe((response) => {
        if(response.isSuccess) {
          this.setTrip(response.trip);
        }
      });
    });
    return promise;
  }

  public updateAttendeesByWayOfDeclination(attendees: Attendee[]) {
    var tripId = this._currentTrip.id;
    
    this._accountsService.removeTripFromCamperWithTripsAndFriends(tripId);
    var promise = new Promise<void>((resolve, reject) => {
      var request = new UpdateAttendeesRequest({ 
        tripId: tripId,
        attendees: attendees
      });
      this._treeloveAPIService.updateAttendees(request).subscribe((response) => {
        if(response.isSuccess) {
          resolve();
        } else {
          reject();
        }
      });
    });
    return promise;
  }

  


}
