import { GroupItem, SharedMeal } from "../services/treelove-api.service";

export class SharedMealDisplay extends SharedMeal {
    constructor(item: SharedMeal, currentCamperId: string) {
        super(item);
        this.currentCamperIsProviding = currentCamperId === this.providerId;
        this.currentCamperHasRSVPed = item.participantIds.includes(currentCamperId);
    }

    isExpanded: boolean = false;
    currentCamperIsProviding: boolean;
    currentCamperHasRSVPed: boolean;
    
    
}