import { Trip, CamperProfile, TripPreview } from '../services/treelove-api.service';

export class CamperWithTrips {
    camper: CamperProfile;
    trips: Trip[];
}

export class CamperWithTripsAndFriends {
    camperProfile: CamperProfile;
    trips: TripPreview[];
    friends: CamperProfile[];
    friendshipRequests: CamperProfile[];
}
