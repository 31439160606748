import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CamperWithTripsAndFriends } from '../models/camper';
import { AccountsService } from './accounts.service';
import { CamperService } from './camper.service';
import { CamperProfile, TreeloveApiService, SearchForCamperRequest,
  SearchForCamperResponse, FriendshipRequest, Response, CamperEntity } from './treelove-api.service';
import * as fuzzysort from "fuzzysort";

@Injectable({
  providedIn: 'root'
})
export class FriendshipService {
  private _camperWithTripsAndFriends: CamperWithTripsAndFriends;
  private currentFriend: CamperEntity;
  public currentFriendSubject: BehaviorSubject<CamperEntity> = new BehaviorSubject<CamperEntity>(null);

  constructor(private treeloveAPIService: TreeloveApiService, private _accountsService: AccountsService, 
    private _router: Router) {
      this._accountsService.currentCamperWithTripsAndFriendsSubject.subscribe((camperWithTripsAndFriends: CamperWithTripsAndFriends) => {
        if (camperWithTripsAndFriends) {
          this._camperWithTripsAndFriends = camperWithTripsAndFriends;
        }
      });
      
   }


   public searchCampers(searchText: string) {
    const request = new SearchForCamperRequest();
    // request.treeloveId = this.currentCamper.treeloveId;
    request.searchText = searchText;
    const promise = new Promise((resolve, reject) => {
      this.treeloveAPIService.searchForCamper(request).subscribe((response: SearchForCamperResponse) => {
        resolve(response.campers);
      });
    });
    return promise;
  }

  public searchFriends(searchText: string) {
    var objects = this._camperWithTripsAndFriends.friends;
    var promise = fuzzysort.goAsync(searchText, objects, {
      key: 'name'
    });
    
    return promise;
  }

   public sendFriendRequest(friend: CamperEntity) {
    const request = new FriendshipRequest();
    // request.treeloveId = this.currentCamper.treeloveId;
    request.friendCamperProfileId = friend.id;
    const promise = new Promise<void>((resolve, reject) => {
      this.treeloveAPIService.requestFriendship(request).subscribe((response: Response) => {
        resolve();
      });
    });
    return promise;
  }

  public acceptFriend(friend: CamperEntity) {
    const request = new FriendshipRequest();
    request.friendCamperProfileId = friend.id;
    const promise = new Promise<void>((resolve, reject) => {
      this.treeloveAPIService.acceptFriendship(request).subscribe((response: Response) => {
        resolve();
      });
    });
    return promise;
  }

  private setCurrentFriend(friend: CamperEntity) {
    this.currentFriend = friend;
    this.currentFriendSubject.next(this.currentFriend);
  }

  public viewFriend(friend: CamperEntity) {
    this.setCurrentFriend(friend);
    this._router.navigateByUrl('/friends/profile');
  }
}
