<ion-content class="jacobi-auth-flow jacobi-auth-flow-reset-pw-final">
  <app-tl-header></app-tl-header>
  <ion-grid>
    <ion-row class="reset-pw-final-outer-row outer-row">
      <ion-col
        class="content-col"
        [attr.size-sm]="splitScreenBgImage ? 12 : undefined"
        [attr.size-md]="splitScreenBgImage ? 6 : undefined"
        [attr.offset-md]="splitScreenBgImage ? 6 : undefined"
        [attr.pull-md]="splitScreenBgImage ? 3 : undefined"
        [attr.size-lg]="splitScreenBgImage ? 6 : undefined"
        [attr.offset-lg]="splitScreenBgImage ? 0 : undefined"
        [attr.pull-lg]="splitScreenBgImage ? 0 : undefined"
      >
        <ion-row>
          <ion-col
            size-sm="12"
            size-md="12"
            size-lg="8"
            offset-lg="2"
            [attr.size-xl]="    (splitScreenBgImage ? 8  : 6)"
            [attr.offset-xl]="  (splitScreenBgImage ? 2  : 3)"
          >
            <ion-row class="page-heading">
              <ion-col>
                Create New Password
                <p class="sub-heading">Enter a new password for your account</p>
              </ion-col>
            </ion-row>

            <ion-row class="submission-msg">
              <ion-col *ngIf="isSuccess"> Success </ion-col>
              <ion-col *ngIf="isFailure"> Something went wrong </ion-col>
            </ion-row>
    
            <ion-row class="submission-sub-msg" *ngIf="isSuccess">
              <ion-col [innerHTML]="successMessage"></ion-col>
            </ion-row>
            <ion-row *ngIf="isFailure" class="submission-sub-msg">
              <ion-col>
                {{ failureMessage }}
                <a (click)="setIsForgotPassword()" class="clickable">
                  Forgot Password?</a
                >
              </ion-col>
            </ion-row>
    
            <ion-row class="form-row jacobi-auth-form-row">
              <ion-col>
                <form
                  (ngSubmit)="resetPassword()"
                  [formGroup]="resetPasswordForm"
                  class="form-reset-pw-final"
                >
                  <ion-row>
                    <ion-col class="ion-margin-top password-input-wrapper">
                      <ion-item class="input-item">
                        <ion-label position="floating">Password</ion-label>
                        <ion-input [type]="passwordInputType" [formControlName]="'password'"></ion-input>
                        <ion-icon class="form-icon" slot="end" [name]="passwordEyeIcon" (click)="onTogglePasswordHide()"></ion-icon>
                      </ion-item>
                      <div *ngIf="resetPasswordForm.controls.password.invalid && resetPasswordForm.controls.password.touched">
                        
                        <ion-text color="danger" *ngIf="resetPasswordForm.controls.password.errors?.['required']">
                            Password is required.
                        </ion-text>
                        <ion-text color="danger" *ngIf="resetPasswordForm.controls.password.errors?.['minlength']">
                            Password must be at least 8 characters.
                        </ion-text>
                      </div>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col class="ion-margin-top password-confirm-input-wrapper">
                      <ion-item class="input-item">
                        <ion-label position="floating">Confirm</ion-label>
                        <ion-input [type]="passwordInputType" [formControlName]="'passwordConfirm'"></ion-input>
                        <ion-icon class="form-icon" slot="end" [name]="passwordEyeIcon" (click)="onTogglePasswordHide()"></ion-icon>
                      </ion-item>
                      <div *ngIf="resetPasswordForm.controls.passwordConfirm.invalid && resetPasswordForm.controls.passwordConfirm.touched">
                          <ion-text color="danger" *ngIf="resetPasswordForm.controls.passwordConfirm.errors?.['passwordConfirmMustMatch']">
                              The confirmation password does not match.'
                          </ion-text>
                      </div>
                    </ion-col>
                  </ion-row>
    
                  <ion-row class="auth-action-buttons">
                    <ion-col>
                      <ion-row *ngIf="!isSuccess">
                        <ion-col class="ion-margin-top reset-btn-wrapper">
                          <ion-button (click)="(!(resetPasswordForm.invalid || !canResetPassword) ? resetPassword() : null)" part="button" [disabled]="resetPasswordForm.invalid || !canResetPassword" class="default-auth-btn" fill="clear">
                            Reset
                          </ion-button>
                        </ion-col>
                        <ion-col *ngIf="isSuccess" class="ion-margin-top sign-in-btn-wrapper">
                          <ion-button [routerLink]="'sign-in'" part="button" [disabled]="resetPasswordForm.invalid" class="default-auth-btn" fill="clear">
                            Sign In
                          </ion-button>
                        </ion-col>
                      </ion-row>
                      <ion-row>
                        <ion-col class="ion-margin-top cancel-btn-wrapper">
                          <ion-button (click)="dismiss()"  part="button" class="default-auth-btn" fill="clear">
                            Cancel
                          </ion-button>
                        </ion-col>
                      </ion-row>
                    </ion-col>
                  </ion-row>
                </form>
              </ion-col>
            </ion-row>

          </ion-col>
        </ion-row>

        
      </ion-col>

      <!-- split-screen -->
      <ion-col
        *ngIf="splitScreenBgImage"
        size-lg="6"
        class="hide-lg-and-below split-page-banner"
        [ngStyle]="{
          'background-image': 'url(' + splitScreenBgImage + ')',
          'background-repeat': 'no-repeat',
          'background-position': 'center center',
          'background-size': 'cover'
        }"
      >
        <div class="img-overlay"></div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
