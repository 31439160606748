import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { InviteRequest } from './treelove-api.service';

export class InviteResponse {
  inviterId: string;
  tripId: string;
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  inviteCamperIdStorageKey: string = 'invite-camper-id';
  inviteTripIdStorageKey: string = 'invite-trip-id';
  inviteCamperId: string = null;
  inviteTripId: string = null;
  private _storage: Storage | null = null;
  private storageInitialized: Promise<void>;

  constructor(private storage: Storage) {
    this.storageInitialized = this.init();
  }

  private async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
  }

  private async ensureStorageInitialized() {
    if (!this._storage) {
      await this.storageInitialized;
    }
  }

  public async set(key: string, value: any) {
    await this.ensureStorageInitialized();
    await this._storage?.set(key, value);
  }

  public async get(key: string) {
    await this.ensureStorageInitialized();
    return await this._storage?.get(key);
  }

  public async remove(key: string) {
    await this.ensureStorageInitialized();
    return await this._storage?.remove(key);
  }

  public async setInvite(inviteCamperId: string, inviteTripId: string) {
    await this.ensureStorageInitialized();
    if (inviteCamperId) {
      this.inviteCamperId = inviteCamperId;
      await this._storage.set(this.inviteCamperIdStorageKey, inviteCamperId);
      if (inviteTripId) {
        this.inviteTripId = inviteTripId;
        await this._storage.set(this.inviteTripIdStorageKey, inviteTripId);
      }
    }
  }

  public async getInvite() {
    await this.ensureStorageInitialized();
    var response = new InviteResponse();
    var promise = new Promise<InviteResponse>((resolve, reject) => {
      if (this.inviteCamperId) {
        response.inviterId = this.inviteCamperId;
        if (this.inviteTripId) {
          response.tripId = this.inviteTripId;
        }
        resolve(response);
      } else {
        this._storage?.get(this.inviteCamperIdStorageKey).then((inviteCamperId: string) => {
          if (inviteCamperId) {
            this.inviteCamperId = inviteCamperId;
            response.inviterId = inviteCamperId;
            this._storage?.get(this.inviteTripIdStorageKey).then((inviteTripId: string) => {
              this.inviteTripId = inviteTripId;
              response.tripId = inviteTripId;
              resolve(response);
            });
          } else {
            this.clearInvite();
            resolve(response);
          }
        });
      }
    });

    return promise;
  }

  public async clearInvite() {
    await this.ensureStorageInitialized();
    this.inviteCamperId = null;
    this.inviteTripId = null;
    await this._storage.remove(this.inviteCamperIdStorageKey);
    await this._storage.remove(this.inviteTripIdStorageKey);
  }
}
