import { Component, Input, OnInit } from '@angular/core';
import { FriendshipService } from 'src/app/services/friendship.service';
import { CamperEntity, CamperProfile } from 'src/app/services/treelove-api.service';

@Component({
  selector: 'app-treelove-avatar',
  templateUrl: './treelove-avatar.component.html',
  styleUrls: ['./treelove-avatar.component.scss'],
})
export class TreeloveAvatarComponent implements OnInit {
  @Input() extraInfo: string;
  @Input() camper: CamperProfile | CamperEntity;
  @Input() showBorder: boolean;
  constructor(private _friendshipService: FriendshipService) { }

  ngOnInit() {
    
  }

  // viewFriend(friend: CamperEntity) {
  //   this._friendshipService.viewFriend(friend);
  // }
}
