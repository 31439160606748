import { GroupItem, Carpool } from "../services/treelove-api.service";

export class CarpoolDisplay extends Carpool {
    constructor(item: GroupItem, currentCamperId: string) {
        super(item);
        this.currentCamperIsProviding = currentCamperId === this.providerId;
        this.riderIds = this.riderIds ?? [];
        this.currentCamperIsRidingWith   = this.riderIds.some(x => x === currentCamperId);
    }

    isExpanded: boolean = false;
    currentCamperIsProviding: boolean;

    currentCamperIsRidingWith: boolean;


    
    
}