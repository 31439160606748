import { Attendee, CamperEntity, Trip } from "../services/treelove-api.service";
import { InvitationStatusTypes } from "./constants";

export class TripDisplay extends Trip {
    constructor(trip: Trip = null, camperId: string = null) {
        super(trip);

        if(camperId) {
            this.setCurrentCamperHostStatus(camperId);
            this.setCurrentCamperInvitationStatus(camperId);
        }
        if(this.attendees) {
            this.setAttendees(this.attendees);
        }
    }

    setAttendees(attendees: Attendee[]) {
        this.attendees = attendees;
        this.campersInvited = this.attendees.filter(x => x.status === InvitationStatusTypes.INVITED).map(x => x.camper);
        this.campersMaybe = this.attendees.filter(x => x.status === InvitationStatusTypes.MAYBE).map(x => x.camper);
        this.campersAccepted = this.attendees.filter(x => x.status === InvitationStatusTypes.ACCEPTED).map(x => x.camper);
    }

    setCurrentCamperHostStatus(camperId: string) {
        var host = this.hosts.find(x => x.camper.id === camperId);
        if(host) {
            this.currentCamperIsHost = true;
            this.currentCamperHostTitle = host.title;
        } else {
            this.currentCamperIsHost = false;
            this.currentCamperHostTitle = null;
        }
    }

    setCurrentCamperInvitationStatus(camperId: string) {
        this.currentCamperInvitationStatus = this.attendees.find(x => x.camper.id === camperId).status;
    }
    currentCamperInvitationStatus: string;
    currentCamperIsHost: boolean;
    currentCamperHostTitle: string;
    campersInvited: CamperEntity[];
    campersMaybe: CamperEntity[];
    campersAccepted: CamperEntity[];
}