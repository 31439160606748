import { GroupItem, Carpool, InventoryCatalogItem } from "../services/treelove-api.service";

export class InventoryCatalogItemDisplay extends InventoryCatalogItem {
    constructor(item: InventoryCatalogItem) {
        super(item);
    }

    isExpanded: boolean = false;
    isSelected: boolean = false;

    requiredCount: number = 1;
    alreadyExists: boolean = false;
    
    
}