import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { AccountsService } from '../services/accounts.service';
import { StorageService } from '../services/storage.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(private _router: Router, private _authService: AuthService, private _storageService: StorageService) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const promise = new Promise<boolean | UrlTree>((resolve, reject) => {
      var inviteId = route.paramMap.get('inviteId');
      if (inviteId) {
        var tripId = route.paramMap.get('tripId');
        this._storageService.setInvite(inviteId, tripId);
        this._router.navigateByUrl('/');
      }
      this._authService.isAuthenticated().then((isAuthenticated) => {
        if (isAuthenticated) {
          resolve(true);
        } else {
          var redirectPath = environment.unauthenticatedRedirect;
          resolve(this._router.parseUrl(redirectPath));
        }
      });
    });
    return promise;
    
  }
}
