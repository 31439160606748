import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TreeloveApiService, API_BASE_URL } from './services/treelove-api.service';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';


import { getApp, initializeApp,provideFirebaseApp } from '@angular/fire/app';

// import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { IonicStorageModule } from '@ionic/storage-angular';
import { SharedModule } from './shared/shared.module';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

import { Storage } from '@ionic/storage-angular';
// import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { register } from 'swiper/element/bundle';
import { TokenInterceptorService } from './token-interceptor.service';
import { provideAuth } from '@angular/fire/auth';
import { initializeAuth, indexedDBLocalPersistence, getAuth } from 'firebase/auth';

import { provideAnalytics, getAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';

import { MessagingModule, getMessaging, provideMessaging } from '@angular/fire/messaging';
import { Capacitor } from '@capacitor/core';
import { ServiceWorkerModule } from '@angular/service-worker';

register();


export function getBaseUrl(): string {
  return environment.treeloveCampingAPIBaseUrl;
}




@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot({ 
          animated: false, 
          platform: {
            'desktop': (win) => {
              const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(win.navigator.userAgent);
              return !isMobile;
            }
          }
        }),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        SharedModule,
        // provideMessaging(() => getMessaging()),
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        
        provideAnalytics(() => getAnalytics()),
        provideMessaging(() => getMessaging()),
        provideAuth(() => {
          if (Capacitor.isNativePlatform()) {
            return initializeAuth(getApp(), {
              persistence: indexedDBLocalPersistence
            });
          } else {
            return getAuth();
          }
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        Facebook,
        StatusBar,
        Storage,
        TreeloveApiService,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: API_BASE_URL, useFactory: getBaseUrl },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true }
    ],
    //schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule {}
