import { Component, Input, OnInit } from '@angular/core';
import { PopoverController, PopoverOptions } from '@ionic/angular';
import { CamperEntity } from 'src/app/services/treelove-api.service';

@Component({
  selector: 'app-choose-camper-popover',
  templateUrl: './choose-camper-popover.component.html',
  styleUrls: ['./choose-camper-popover.component.scss'],
})
export class ChooseCamperPopoverComponent implements OnInit {
  
  selectedCamper: CamperEntity;
  @Input() chooseCamperText: string
  @Input() campers;
  constructor(private _popoverController: PopoverController) { }

  ngOnInit() {}

  dismiss(camper: CamperEntity) {
    this._popoverController.dismiss({ camper });
  }

  

}
