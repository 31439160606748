
  <ion-card *ngIf="!trip.bannerPhotoImageSrc" button="true" [ngStyle]="{'background-image': 'url(' + trip.bannerPhotoURL + ')'}">
    <ion-card-content class="ion-text-left ion-text-bottom">
      <ion-card-title>{{trip.name}}</ion-card-title>
      <ion-card-subtitle>{{ trip.startDateUtc | date: 'E, MMM dd' }} - {{ trip.endDateUtc | date: 'E, MMM dd' }}</ion-card-subtitle>  
    </ion-card-content>
  </ion-card>

  <ion-card *ngIf="trip.bannerPhotoImageSrc" button="true">
    <img [src]="trip.bannerPhotoImageSrc"/>
    <ion-card-content class="ion-text-left ion-text-bottom">
      
        
    </ion-card-content>
  </ion-card>