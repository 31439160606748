// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // treeloveCampingUIBaseUrl: 'http://localhost:8100',
  treeloveCampingUIBaseUrl: 'https://treelove.io',
  // treeloveCampingUIBaseUrl: 'https://treelove-ui-d64or7vgta-ue.a.run.app',
  // treeloveCampingAPIBaseUrl: 'http://localhost:9091',
  treeloveCampingAPIBaseUrl: 'https://treelove-api-d64or7vgta-ue.a.run.app',

  firebase: {
    apiKey: "AIzaSyCnqSBqsk8jUPhSLCiqbQkmOnqOkT4bgoQ",
    authDomain: "treelove.firebaseapp.com",
    // authDomain: "https://treelove.io",
    projectId: "treelove",
    storageBucket: "treelove.appspot.com",
    messagingSenderId: "606201494893",
    appId: "1:606201494893:web:89fbf385dbd8bf80d96e64",
    vapidKey: "BDU7X6798PiebYancw5jhTu4Rm7TSNE4ydBwv2-Ztp85Bt1cY-z1l2V58iuas2v1j-ZxAUBudrygJ0FOEtXXAYQ"
  },

  mapboxAccessToken: 'pk.eyJ1IjoidHJlZXNwaGVyZSIsImEiOiJjbDFiZDIzNTcwNWhiM2ltcmdoaDJiM3BjIn0.J8EpqnaiGsJsUB2WlWjS7Q',

  
  appleAppstoreListingUrl: "",
  googlePlayListingUrl: "",
  signInRedirectUrl: '/home',
  signUpRedirectUrl: '/home',
  unauthenticatedRedirect: '/auth-flow/sign-in',
  splitScreenOptions: {
    images: {
      // signIn: 'https://storage.googleapis.com/neat-boutique-dev/images/clark-street-mercantile-qnKhZJPKFD8-unsplash.jpg',
      // signUp: 'https://storage.googleapis.com/neat-boutique-dev/images/clark-street-mercantile-qnKhZJPKFD8-unsplash.jpg',
      signIn: null,
      signUp: null,
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
