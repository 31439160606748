<ion-header class="ion-no-border" *ngIf="showAuthTopper">
  
  <div class="header-container" >
    <div class="background-container">
      <div class="background-filter">
        
      </div>
    </div>
    
    <img [routerLink]="['/home']" class="logo" src="../../../assets/img/TL_Logo_Lg_03.png"/>
  </div>
</ion-header>