<div class="modal-wrapper">
  <ion-card class="modal-card-wrapper">
    <ion-card-header class="logo-wrapper">
      <h1 class="title">{{ rsvpTitle }}</h1>
      <ion-icon class="close-icon clickable" (click)="onClose($event)" name="close-outline"></ion-icon>
      <!-- <span class="close-icon clickable" (click)="onSave($event)">Save</span> -->
    </ion-card-header>
  
    <ion-card-content class="modal-content-wrapper">
      <form [formGroup]="rsvpForm">
        <ion-row *ngIf="trip.currentCamperIsHost || doesCurrentCamperProvide">
          <ion-col>
            <p class="ion-text-center" *ngIf="isCarFull">Remove someone before you can anyone.</p>
            <ion-item style="min-width: 84vw;" class="bordered-item" lines="none">
                <ion-select  cancelText="Cancel" okText="Done" [formControlName]="'providerId'" placeholder="Who are you RSVP'ing for?">
                  <ion-select-option *ngIf="trip.currentCamperIsHost || !carpool" [value]="currentCamper.id">Me</ion-select-option>
                  <ion-select-option *ngFor="let camper of campersToPIck" [value]="camper.id">{{ camper.name }}</ion-select-option>
                </ion-select>
              <ion-icon slot="end" name="accessibility-outline"></ion-icon>
            </ion-item>
            <div *ngIf="rsvpForm.controls.providerId.invalid && rsvpForm.controls.providerId.touched">
              <ion-text color="danger" *ngIf="rsvpForm.controls.providerId.errors?.['required']">
                  Required Field
              </ion-text>
            </div>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="rsvpForm.controls.providerId.value">
          <ion-col>
            <p class="question-text ion-text-center" *ngIf="rsvpForm.controls.providerId.value === currentCamper.id"><strong>Do you plan to {{ activityQuery }}</strong></p>
            <p class="question-text ion-text-center" *ngIf="rsvpForm.controls.providerId.value !== currentCamper.id"><strong>Does {{ getCamper(rsvpForm.controls.providerId.value).name }} plan to {{ activityQuery }}</strong></p>
            <ion-radio-group [formControlName]="'isParticipating'" [value]="1">
              <ion-row class="ion-justify-content-center" >
                <ion-col class="inline-radio-col" size="auto">
                  <ion-item lines="none">
                    <ion-label>Yes</ion-label>
                    <ion-radio slot="start" [value]="true"></ion-radio>
                  </ion-item>
                </ion-col>
                <ion-col class="inline-radio-col" size="auto">
                  <ion-item lines="none">
                    <ion-label>No</ion-label>
                    <ion-radio slot="start" [value]="false"></ion-radio>
                  </ion-item>
                </ion-col>
              </ion-row>
              
            </ion-radio-group>
          </ion-col>
        </ion-row>
        <div *ngIf="rsvpForm.controls.isParticipating.invalid && (rsvpForm.controls.isParticipating.touched)">
          <ion-text color="danger" *ngIf="rsvpForm.controls.isParticipating.errors?.['required']">
              RSVP is required.
          </ion-text>
        </div>

       
      </form>
      
      
      <ion-row>
        <ion-col>
            <ion-button (click)="onSave($event)">Save</ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button (click)="onClose($event)">Cancel</ion-button>
        </ion-col>
      </ion-row>

      <!-- <div>
        <p class="text-notice">{{ mainText }}</p>
        <ion-button class="quantity-selection-button"><ion-icon slot="icon-only" name="remove-outline"></ion-icon></ion-button>
        <h2 class="quantity-selection-quantity">{{ quantity }} / {{ total }}</h2>
        <ion-button class="quantity-selection-button"><ion-icon slot="icon-only" name="add-outline"></ion-icon></ion-button>

          <ion-list>
            <ion-item [lines]="'none'">
              <ion-input inputmode="numeric" [(ngModel)]="quantity" placeholder="How many?" type="number" description="numeric"></ion-input>
            </ion-item>
          </ion-list>
  
          <ion-item-divider></ion-item-divider>
          <div class="action-btn-wrapper">
            <ion-button (click)="onSave($event)">Save</ion-button>
            <ion-button (click)="onClose($event)">Cancel</ion-button>
          </div>
      </div> -->
    </ion-card-content>
  </ion-card>
</div>
