import { Component, Input, OnInit } from '@angular/core';
import { TripPreview } from 'src/app/services/treelove-api.service';

@Component({
  selector: 'app-trip-preview',
  templateUrl: './trip-preview.component.html',
  styleUrls: ['./trip-preview.component.scss'],
})
export class TripPreviewComponent implements OnInit {
  @Input() trip: TripPreview;
  constructor() { }

  ngOnInit() {
    if(!this.trip.bannerPhotoURL) {
      this.trip.bannerPhotoURL = "https://storage.googleapis.com/treelove.io/vendor-photos/b2effa25-4dd1-40af-8833-665cbb4c0289.jpg";
    }
  }

}
