import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { GeoCoordinates, Trip, TripPreview } from 'src/app/services/treelove-api.service';
import { environment } from 'src/environments/environment';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import * as mapboxgl from 'mapbox-gl';

export const CreateTripPageTypes = {
  PAGE_ONE: "Page One",
  PAGE_TWO: "Page Two",
  PAGE_THREE: "Page Three"
}

export class TripCreationResponse {
  trip: Trip;
  bannerPhotoSrc: string;
}

export enum FileEventTypes {
  WRONG_FILE_TYPE_DETECTED,
  IMAGE_LOADING,
  IMAGE_FINISHED_LOADING,
};

@Component({
  selector: 'app-trip-creation-modal',
  templateUrl: './trip-creation-modal.component.html',
  styleUrls: ['./trip-creation-modal.component.scss'],
})
export class TripCreationModalComponent implements OnInit {
  @Input() trip: Trip;
  tripPreview: TripPreview = null;
  createTripPageTypes = CreateTripPageTypes;
  currentPage = CreateTripPageTypes.PAGE_ONE;
  highlightedDates = [];
  showBannerPhotoLinkInput = false;
  fileEventTypes = FileEventTypes;
  public fileEvent: FileEventTypes = null;
  isUploadingImage: boolean = false;
  public loadingImg: boolean;
  public newTripPageOneForm = new FormGroup({
    name: new FormControl('', [ Validators.required ]),
    startDateUtc: new FormControl(null, [ Validators.required ]),
    endDateUtc: new FormControl(null, [ Validators.required ]),
  });

  public newTripPageTwoForm = new FormGroup({
    location: new FormControl(null , [ Validators.required  ])
  });

  public newTripPageThreeForm = new FormGroup({
    bannerPhotoUrl: new FormControl('', [ ]),
    helpfulLinkUrl: new FormControl('', [ ]),
    notes: new FormControl('', [ ])
  });

  private _pageLoadPromises: Promise<boolean>[] = [];
  private mapbox: mapboxgl.Map;

  public selectedLocationMarker;
  public showLocation: boolean;

  @ViewChild('mapElement') mapElement: ElementRef;
  private _initialGeoLocation: number[];
  
  // highlightedDates = [];
  constructor(private _modalController: ModalController, private _geolocation: Geolocation) { 
    mapboxgl.accessToken = environment.mapboxAccessToken;
    
  }

  ionViewDidEnter() {
    const modalCardHeight = document.querySelector('.modal-card-wrapper')?.clientHeight;
    const modalCardWidth = document.querySelector('.modal-card-wrapper')?.clientWidth;
    document.documentElement.style.setProperty('--current-modal-card-height', `${modalCardHeight}px`);
    document.documentElement.style.setProperty('--current-modal-card-width', `${modalCardWidth}px`);
  }


  ngOnInit() {
    
  }

  onClose(event) {
    this._modalController.dismiss(null);
  }


  goToPage2() {
    if(this.newTripPageOneForm.invalid){
      this.newTripPageOneForm.markAllAsTouched();
      return;
    } else {
      this.currentPage = CreateTripPageTypes.PAGE_TWO;
      var geolocatePromise = new Promise<boolean>((resolve, reject) => {
        this._geolocation.getCurrentPosition().then((resp) => {
          this._initialGeoLocation = [resp.coords.longitude, resp.coords.latitude];
          resolve(true);
        });
      });
  
      var checkExist = setInterval(() => {
        if (this.mapElement?.nativeElement) {
          Promise.all(this._pageLoadPromises).then(() => this._initMap());
          // this._initMap();
          clearInterval(checkExist);
        }
      }, 100); 
    }
  }

  goToPage3() {
    if(this.newTripPageTwoForm.invalid){
      this.newTripPageTwoForm.markAllAsTouched();
      return;
    } else {
      this.currentPage = CreateTripPageTypes.PAGE_THREE;
    }
  }
  save() {
    if(this.newTripPageThreeForm.invalid){
      this.newTripPageThreeForm.markAllAsTouched();
      return;
    } else {
      var helpfulLink = "";
      if(this.isUploadingImage) {
        // save image
      } else {
        helpfulLink = this.newTripPageThreeForm.controls.helpfulLinkUrl.value
      }

      this.trip.name = this.newTripPageOneForm.controls.name.value;
      this.trip.notes = this.newTripPageThreeForm.controls.notes.value;
      this.trip.startDateUtc = this.newTripPageOneForm.controls.startDateUtc.value;
      this.trip.endDateUtc = this.newTripPageOneForm.controls.endDateUtc.value;
      this.trip.bannerPhotoURL = this.newTripPageThreeForm.controls.bannerPhotoUrl.value;
      this.trip.helpfulLinkURL = helpfulLink;
      this.trip.location.geoLocation = new GeoCoordinates();
      this.trip.location.geoLocation.latitude = this.newTripPageTwoForm.controls.location.value.latitude;
      this.trip.location.geoLocation.longitude = this.newTripPageTwoForm.controls.location.value.longitude;
      
      var response = new TripCreationResponse();
      response.trip = this.trip;
      response.bannerPhotoSrc = this.tripPreview?.bannerPhotoImageSrc;
      this._modalController.dismiss(response);
    }
  }
  
  selectDate(event) {
    const date = new Date(event.detail.value);

    if(this.newTripPageOneForm.controls.startDateUtc.value && this.newTripPageOneForm.controls.endDateUtc.value) {
      this.newTripPageOneForm.controls.startDateUtc.setValue(date);
      this.newTripPageOneForm.controls.endDateUtc.setValue(null);
      this.highlightedDates = [];
    } else if(!this.newTripPageOneForm.controls.startDateUtc.value && !this.newTripPageOneForm.controls.endDateUtc.value) {
      this.newTripPageOneForm.controls.startDateUtc.setValue(date);
    } else if (this.newTripPageOneForm.controls.startDateUtc.value && !this.newTripPageOneForm.controls.endDateUtc.value) {
      this.newTripPageOneForm.controls.endDateUtc.setValue(date);

      var startDate = new Date(this.newTripPageOneForm.controls.startDateUtc.value);
      var endDate = new Date(this.newTripPageOneForm.controls.endDateUtc.value);

      if(endDate < startDate) {
        this.newTripPageOneForm.controls.startDateUtc.setValue(date);
        this.newTripPageOneForm.controls.endDateUtc.setValue(null);
        return;
      }
      var selectedDates = [];

      var dayToPick = {
        date: new Date(startDate.toDateString()).toISOString().split('T')[0],
        textColor: '#ffffff',
        backgroundColor: '#06715d',
      };
      new Date(startDate);
      selectedDates.push(dayToPick);
      startDate.setDate(startDate.getDate() + 1);
      // Add all dates between the first and second dates.
      while (startDate <= endDate) {
        var dayToPick = {
          date: new Date(startDate.toDateString()).toISOString().split('T')[0],
          textColor: '#09721b',
          backgroundColor: '#c8e5d0',
        };
        new Date(startDate);
        selectedDates.push(dayToPick);
        startDate.setDate(startDate.getDate() + 1);
      }

      this.highlightedDates = selectedDates;
    }
  }

  private _initMap() {
    var coordinates = this._initialGeoLocation
    
		this.mapbox = new mapboxgl.Map({
			container: this.mapElement.nativeElement,
			style: `mapbox://styles/mapbox/outdoors-v11`,
			zoom: 12,
			center: coordinates,
      
		});
    var geoLocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      // When active the map will receive updates to the device's location as it changes.
      trackUserLocation: true,
      // Draw an arrow next to the location dot to indicate which direction the device is heading.
      showUserHeading: true,
    });
    
    var locationSelectionPopup = new mapboxgl.Popup({offset: 25})
      .setText('This is your trip location. To remove, click \'clear location\'');
      //.setHTML(popUpButtonHtml);
   
    this.selectedLocationMarker = this.createTreeloveMarker().setDraggable(true).setPopup(locationSelectionPopup);// new mapboxgl.Marker().setDraggable(true).setPopup(locationSelectionPopup);
    var locationMarkerClicked = false;
    this.selectedLocationMarker.on('dragend', (e) => {
      this.newTripPageTwoForm.controls.location.setValue(new GeoCoordinates({ 
        longitude: this.selectedLocationMarker._lngLat.lng, 
        latitude: this.selectedLocationMarker._lngLat.lat
      }));
    });
    this.selectedLocationMarker.getElement().addEventListener('click', (e) => {
      locationMarkerClicked = true;
    })
    this.mapbox.on('click', (e)=> {
      if(locationMarkerClicked) {
        locationMarkerClicked = false;
        return;
      }
      var coordinates = e.lngLat;
      this.selectedLocationMarker.setLngLat(coordinates).addTo(this.mapbox);
      this.newTripPageTwoForm.controls.location.setValue(new GeoCoordinates({ 
        longitude: this.selectedLocationMarker._lngLat.lng, 
        latitude: this.selectedLocationMarker._lngLat.lat
      }));
      this.showLocation = true;

    });
    

    var geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      marker: false
    }).on('result', (selected) => {
      this.selectedLocationMarker.setLngLat(selected.result.center).addTo(this.mapbox);
      
      this.newTripPageTwoForm.controls.location.setValue(new GeoCoordinates({ 
        longitude: this.selectedLocationMarker._lngLat.lng, 
        latitude: this.selectedLocationMarker._lngLat.lat
      }));
      this.showLocation = true;
      this.trip.location.address = selected.result.place_name;
      //this.trip.location.address = selected.result;
      // this.showTreeloveMarker(selected.result.center);
      geocoder.clear();
    });
    this.mapbox.addControl(geocoder);
    this.mapbox.addControl(geoLocate);

    // this.mapbox.on('preclick', (e) => {
    //   var testt = document.elementFromPoint(e.point.x, e.point.y);
    //   var test = e;
    // })
    
    
    this.mapbox.on('load', () => 
    {
      
        geoLocate.trigger();
      
    });
      

	}

  createTreeloveMarker() {
    var el = document.createElement('div');
    el.className = 'treelove-marker';
    const marker = new mapboxgl.Marker(el);
    return marker;
  }

  showTreeloveMarker(lngLat) {
    this.mapbox.loadImage('../../../../assets/img/treelove-marker.png', (error, image) => {
      if (error) throw error;
      
      // Add the image to the map style.
      this.mapbox.addImage('treelove-marker', image);
      
      // Add a data source containing one point feature.
      this.mapbox.addSource('point', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': [
            {
              'type': 'Feature',
              'geometry': {
                'type': 'Point',
                'coordinates': lngLat
              }
            }
          ]
        }
      });
      
      // Add a layer to use the image to represent the data.
      this.mapbox.addLayer({
        'id': 'points',
        'type': 'symbol',
        'source': 'point', // reference the data source
        'layout': {
        'icon-image': 'treelove-marker', // reference the image
        'icon-size': 0.05
        }
      });
    });
  }

  private flyTo(longitude, latitude) {
    this.mapbox.flyTo({
      center: [longitude, latitude],
      essential: true // this animation is considered essential with respect to prefers-reduced-motion
      });
  }

  clearLocationSelection() {
    this.selectedLocationMarker.remove();
    this.showLocation = false;
    this.trip.location = null;
  }

  
  saveImage() {
    var test = 5;
  }

  selectImg(event) {
    this.isUploadingImage = true;
    this.fileEvent = FileEventTypes.IMAGE_LOADING;
    this.loadingImg = true;
    let formData = new FormData();
    let files: FileList;
    files = event.target?.files;

    if (files && (files.length > 0)) {
      formData.append('photo', files[0], files[0].name);
      const reader = new FileReader();
      // define how logo file should be processed
      reader.onload = (e: any) => {
        const fileType = files[0].type;        
        if (fileType.includes('application')) {
          // this.croppedImagePreview = null;
          this.fileEvent = FileEventTypes.WRONG_FILE_TYPE_DETECTED;
          return null;
        }

        const image = new Image();
        image.src = e.target.result;
        this.tripPreview = new TripPreview({
          bannerPhotoImageSrc: image.src,
          startDateUtc: this.newTripPageOneForm.controls.startDateUtc.value,
          endDateUtc: this.newTripPageOneForm.controls.endDateUtc.value,
          name: this.newTripPageOneForm.controls.name.value
        });
        
        
        this.fileEvent = null;

        image.onload = (rs: any) => {     
          // if(this._cropper) {
          //   // remove old img
          //   this._cropper.destroy();
          // }
          // // create new cropper
          // this._cropper = new Cropper(this.referenceImageElement?.nativeElement, {
          //   zoomable: false,
          //   scalable: false,
          //   crop: () => {              
          //     const canvas = this._cropper.getCroppedCanvas();
          //     this.croppedImagePreview = canvas.toDataURL();     
          //   }
          // });
          // // change the image to uploaded file
          // this.loadingImg = false;
          // cropBoxAspectRatio = 3 / 2;
          // if(this.isCircular) {
          //   var cropBoxAspectRatio = 1;
          // }
          // this._cropper.setAspectRatio(cropBoxAspectRatio);
          // this._cropper.replace(image.src);
        };
      };
      // process file      
      reader.readAsDataURL(files[0]);
    }
  }

}
