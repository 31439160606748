
<div class="modal-wrapper">
  <ion-card class="modal-card-wrapper">
    <ion-card-header class="logo-wrapper">
      <h1 *ngIf="isCreatingNewItem" class="title">Group Item</h1>
      <h1 *ngIf="!isCreatingNewItem" class="title">Group Items</h1>
      <ion-icon class="close-icon clickable" (click)="onClose()" name="close-outline"></ion-icon>
      <!-- <span class="close-icon clickable" (click)="onSave($event)">Save</span> -->
    </ion-card-header>
  
    <ion-card-content class="modal-content-wrapper">
      <ion-searchbar *ngIf="!isCreatingNewItem" [(ngModel)]="searchText" (ionClear)="onClearSearch()" (search)="onSearch($event)" ></ion-searchbar>
      <p *ngIf="!isCreatingNewItem" class="ion-text-center clickable" (click)="createNewItem()">don't see what you want to add?</p>
      <div *ngIf="!isCreatingNewItem" class="inventory-catalog">
        <div [ngClass]="{ disabled: catalogItem.alreadyExists }" *ngFor="let catalogItem of inventoryCatalogItemsToShow">
          <ion-row>
            <ion-col size="auto">
              <ion-checkbox [disabled]="catalogItem.alreadyExists" [(ngModel)]="catalogItem.isSelected"></ion-checkbox>
            </ion-col>
            <ion-col *ngIf="!catalogItem.alreadyExists" (click)="catalogItem.isSelected = !catalogItem.isSelected">
              <p> {{ catalogItem.name }} </p>
            </ion-col>
            <ion-col *ngIf="catalogItem.alreadyExists">
              <p> {{ catalogItem.name }} </p>
            </ion-col>
          </ion-row>
          <div *ngIf="catalogItem.isSelected && !catalogItem.alreadyExists">
            <ion-row class="ion-align-items-center quantity-selection-row">
              <ion-col offset="1" size="6">
                <ion-radio-group [(ngModel)]="catalogItem.priority">
                  <ion-radio [value]="priorityTypes.IMPORTANT" labelPlacement="end">{{ priorityTypes.IMPORTANT }}</ion-radio>
                  <ion-radio [value]="priorityTypes.OPTIONAL" labelPlacement="end">{{ priorityTypes.OPTIONAL }}</ion-radio>
                </ion-radio-group>
              </ion-col>
              <ion-col size="auto">
                <ion-button (click)="decreaseQuantity(catalogItem)" class="quantity-selection-button-small"><ion-icon slot="icon-only" name="remove-outline"></ion-icon></ion-button>
              </ion-col>
              <ion-col size="auto" class="ion-align-self-center ion-text-center">
                <h2 class="quantity-selection-quantity">{{ catalogItem.requiredCount }}</h2>
              </ion-col>
              <ion-col size="auto">
                <ion-button (click)="increaseQuantity(catalogItem)"
                  class="quantity-selection-button-small"><ion-icon slot="icon-only" name="add-outline"></ion-icon></ion-button>
              </ion-col>
            </ion-row>
          </div>
        </div>
      </div>



      <form *ngIf="isCreatingNewItem" [formGroup]="groupItemForm">
        <ion-row>
          <ion-col class="ion-text-center">
            <p class="text-notice">How many are needed for the group?</p>
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center">
          <ion-col offset="2" size="3">
            <ion-button (click)="decreaseQuantity(newItem)" class="quantity-selection-button"><ion-icon slot="icon-only" name="remove-outline"></ion-icon></ion-button>
          </ion-col>
          <ion-col size="2" class="ion-align-self-center ion-text-center">
            <h2 class="quantity-selection-quantity">{{ newItem.requiredCount }}</h2>
          </ion-col>
          <ion-col size="3">
            <ion-button (click)="increaseQuantity(newItem)"
             class="quantity-selection-button"><ion-icon slot="icon-only" name="add-outline"></ion-icon></ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item class="input-item">
              <ion-input placeholder="What is the item called?" [formControlName]="'name'"></ion-input>
              <ion-icon class="form-icon" slot="end" name="pencil-outline"></ion-icon>
            </ion-item>
            <div *ngIf="groupItemForm.controls.name.invalid && groupItemForm.controls.name.touched">
              <ion-text color="danger" *ngIf="groupItemForm.controls.name.errors?.['required']">
                  Required Field
              </ion-text>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item style="min-width: 84vw;" class="bordered-item" lines="none">
                <ion-select  cancelText="Cancel" okText="Done" [formControlName]="'priorityType'" placeholder="What's the priority level?">
                  <ion-select-option *ngFor="let type of priorityTypesList" [value]="type">{{ type }}</ion-select-option>
                </ion-select>
              <ion-icon slot="end" name="alert-outline"></ion-icon>
            </ion-item>
            <div *ngIf="groupItemForm.controls.priorityType.invalid && groupItemForm.controls.priorityType.touched">
              <ion-text color="danger" *ngIf="groupItemForm.controls.priorityType.errors?.['required']">
                  Required Field
              </ion-text>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item style="min-width: 84vw;" class="bordered-item" lines="none">
                <ion-select  cancelText="Cancel" okText="Done" [formControlName]="'categoryType'" placeholder="What type of item is this?">
                  <ion-select-option *ngFor="let type of categoryTypes" [value]="type">{{ type }}</ion-select-option>
                </ion-select>
              <ion-icon slot="end" name="apps-outline"></ion-icon>
            </ion-item>
            <div *ngIf="groupItemForm.controls.categoryType.invalid && groupItemForm.controls.categoryType.touched">
              <ion-text color="danger" *ngIf="groupItemForm.controls.categoryType.errors?.['required']">
                  Required Field
              </ion-text>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item class="bordered-textarea-item" lines="none">
              <ion-label position="stacked">Notes</ion-label>
              <ion-textarea [formControlName]="'notes'"></ion-textarea>
            </ion-item>
          </ion-col>
        </ion-row>
      </form>
      
      
      <ion-row>
        <ion-col>
            <ion-button (click)="onSave()">Save</ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button (click)="onClose()">Cancel</ion-button>
        </ion-col>
      </ion-row>

      <!-- <div>
        <p class="text-notice">{{ mainText }}</p>
        <ion-button class="quantity-selection-button"><ion-icon slot="icon-only" name="remove-outline"></ion-icon></ion-button>
        <h2 class="quantity-selection-quantity">{{ quantity }} / {{ total }}</h2>
        <ion-button class="quantity-selection-button"><ion-icon slot="icon-only" name="add-outline"></ion-icon></ion-button>

          <ion-list>
            <ion-item [lines]="'none'">
              <ion-input inputmode="numeric" [(ngModel)]="quantity" placeholder="How many?" type="number" description="numeric"></ion-input>
            </ion-item>
          </ion-list>
  
          <ion-item-divider></ion-item-divider>
          <div class="action-btn-wrapper">
            <ion-button (click)="onSave($event)">Save</ion-button>
            <ion-button (click)="onClose($event)">Cancel</ion-button>
          </div>
      </div> -->
    </ion-card-content>
  </ion-card>
</div>
