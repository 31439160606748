
<div class="modal-wrapper">
  <ion-card class="modal-card-wrapper">
    <ion-card-header class="logo-wrapper">
      <h1 *ngIf="isPrivate && isCreatingNewItem" class="title">Private Item</h1>
      <h1 *ngIf="!isPrivate &&isCreatingNewItem" class="title">Personal Item</h1>
      <h1 *ngIf="isPrivate && !isCreatingNewItem" class="title">Private Items</h1>
      <h1 *ngIf="!isPrivate &&!isCreatingNewItem" class="title">Personal Items</h1>
      <ion-icon class="close-icon clickable" (click)="onClose()" name="close-outline"></ion-icon>
      <!-- <span class="close-icon clickable" (click)="onSave($event)">Save</span> -->
    </ion-card-header>
  
    <ion-card-content class="modal-content-wrapper">
      <p class="ion-text-center warning" *ngIf="!isPrivate">visible to everyone</p>
      <p class="ion-text-center warning" *ngIf="isPrivate">only visible to you</p>
      <ion-searchbar *ngIf="!isCreatingNewItem" [(ngModel)]="searchText" (ionClear)="onClearSearch()" (search)="onSearch($event)" ></ion-searchbar>
      <p *ngIf="!isCreatingNewItem" class="ion-text-center clickable" (click)="createNewItem()">don't see what you want to add?</p>
      <div *ngIf="!isCreatingNewItem" class="inventory-catalog">
        <div [ngClass]="{ disabled: catalogItem.alreadyExists }" *ngFor="let catalogItem of inventoryCatalogItemsToShow">
          <ion-row>
            <ion-col size="auto">
              <ion-checkbox [disabled]="catalogItem.alreadyExists" [(ngModel)]="catalogItem.isSelected"></ion-checkbox>
            </ion-col>
            <ion-col *ngIf="!catalogItem.alreadyExists" (click)="catalogItem.isSelected = !catalogItem.isSelected">
              <p> {{ catalogItem.name }} </p>
            </ion-col>
            <ion-col *ngIf="catalogItem.alreadyExists">
              <p> {{ catalogItem.name }} </p>
            </ion-col>
          </ion-row>
          <div *ngIf="catalogItem.isSelected && !catalogItem.alreadyExists">
            <ion-row class="ion-align-items-center quantity-selection-row">
              <ion-col offset="1" size="6">
                <ion-radio-group [(ngModel)]="catalogItem.priority">
                  <ion-radio [value]="priorityTypes.IMPORTANT" labelPlacement="end">{{ priorityTypes.IMPORTANT }}</ion-radio>
                  <ion-radio [value]="priorityTypes.OPTIONAL" labelPlacement="end">{{ priorityTypes.OPTIONAL }}</ion-radio>
                </ion-radio-group>
              </ion-col>
              <ion-col size="auto">
                <ion-button (click)="decreaseQuantity(catalogItem)" class="quantity-selection-button-small"><ion-icon slot="icon-only" name="remove-outline"></ion-icon></ion-button>
              </ion-col>
              <ion-col size="auto" class="ion-align-self-center ion-text-center">
                <h2 class="quantity-selection-quantity">{{ catalogItem.requiredCount }}</h2>
              </ion-col>
              <ion-col size="auto">
                <ion-button (click)="increaseQuantity(catalogItem)"
                  class="quantity-selection-button-small"><ion-icon slot="icon-only" name="add-outline"></ion-icon></ion-button>
              </ion-col>
            </ion-row>

          </div>
          
        </div>
      </div>


      <form *ngIf="isCreatingNewItem" [formGroup]="personalItemForm">
        <ion-row>
          <ion-col class="ion-text-center">
            <p class="text-notice">How many should each camper bring?</p>
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center">
          <ion-col offset="2" size="3">
            <ion-button (click)="decreaseQuantity(newItem)" class="quantity-selection-button"><ion-icon slot="icon-only" name="remove-outline"></ion-icon></ion-button>
          </ion-col>
          <ion-col size="2" class="ion-align-self-center ion-text-center">
            <h2 class="quantity-selection-quantity">{{ newItem.requiredCount }}</h2>
          </ion-col>
          <ion-col size="3">
            <ion-button (click)="increaseQuantity(newItem)"
             class="quantity-selection-button"><ion-icon slot="icon-only" name="add-outline"></ion-icon></ion-button>
          </ion-col>
        </ion-row> 
        <ion-row>
          <ion-col>
            <ion-item class="input-item">
              <ion-input placeholder="What is the item called?" [formControlName]="'name'"></ion-input>
              <ion-icon class="form-icon" slot="end" name="pencil-outline"></ion-icon>
            </ion-item>
            <div *ngIf="personalItemForm.controls.name.invalid && personalItemForm.controls.name.touched">
              <ion-text color="danger" *ngIf="personalItemForm.controls.name.errors?.['required']">
                  Required Field
              </ion-text>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item style="min-width: 84vw;" class="bordered-item" lines="none">
                <ion-select  cancelText="Cancel" okText="Done" [formControlName]="'priorityType'" placeholder="What's the priority level?">
                  <ion-select-option *ngFor="let type of priorityTypesList" [value]="type">{{ type }}</ion-select-option>
                </ion-select>
              <ion-icon slot="end" name="alert-outline"></ion-icon>
            </ion-item>
            <div *ngIf="personalItemForm.controls.priorityType.invalid && personalItemForm.controls.priorityType.touched">
              <ion-text color="danger" *ngIf="personalItemForm.controls.priorityType.errors?.['required']">
                  Required Field
              </ion-text>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item style="min-width: 84vw;" class="bordered-item" lines="none">
                <ion-select  cancelText="Cancel" okText="Done" [formControlName]="'categoryType'" placeholder="What type of item is this?">
                  <ion-select-option *ngFor="let type of categoryTypes" [value]="type">{{ type }}</ion-select-option>
                </ion-select>
              <ion-icon slot="end" name="apps-outline"></ion-icon>
            </ion-item>
            <div *ngIf="personalItemForm.controls.categoryType.invalid && personalItemForm.controls.categoryType.touched">
              <ion-text color="danger" *ngIf="personalItemForm.controls.categoryType.errors?.['required']">
                  Required Field
              </ion-text>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item class="bordered-textarea-item" lines="none">
              <ion-label position="stacked">Notes</ion-label>
              <ion-textarea [formControlName]="'notes'"></ion-textarea>
            </ion-item>
          </ion-col>
        </ion-row>
      </form>
      
      
      <ion-row>
        <ion-col>
            <ion-button (click)="onSave()">Save</ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button (click)="onClose()">Cancel</ion-button>
        </ion-col>
      </ion-row>






















































      <!-- <ion-row *ngIf="shouldViewItemsByCategory">
        <div *ngIf="campSiteItems?.length > 0">
          <ion-item>
            <ion-label text-wrap>
              <h1>{{ packingCategoryTypes.CAMP_SITE }}</h1>
            </ion-label>
            <span *ngIf="!isCampSiteExpanded" class="ion-text-end" (click)="isCampSiteExpanded = true">
              <ion-icon name="chevron-down-outline"></ion-icon>
            </span>
            <span *ngIf="isCampSiteExpanded" class="ion-text-end" (click)="isCampSiteExpanded = false">
              <ion-icon name="chevron-up-outline"></ion-icon>
            </span>
          </ion-item>
          <app-expandable [expanded]="isCampSiteExpanded">
            <ion-list lines="none">
              <div *ngFor="let item of campSiteItems">
                <app-personal-item [item]="item"></app-personal-item>
              </div>
            </ion-list>
          </app-expandable>
        </div>
        
        <div *ngIf="toolItems?.length > 0">
          <ion-item>
            <ion-label text-wrap>
              <h1>{{ packingCategoryTypes.TOOLS }}</h1>
            </ion-label>
            <span *ngIf="!isToolsExpanded" class="ion-text-end" (click)="isToolsExpanded = true">
              <ion-icon name="chevron-down-outline"></ion-icon>
            </span>
            <span *ngIf="isToolsExpanded" class="ion-text-end" (click)="isToolsExpanded = false">
              <ion-icon name="chevron-up-outline"></ion-icon>
            </span>
          </ion-item>
          <app-expandable [expanded]="isToolsExpanded">
            <ion-list lines="none">
              <div *ngFor="let item of toolItems">
                <app-personal-item [item]="item"></app-personal-item>
              </div>
            </ion-list>
          </app-expandable>
        </div>
    
        <div *ngIf="kitchenItems?.length > 0">
          <ion-item>
            <ion-label text-wrap>
              <h1>{{ packingCategoryTypes.KITCHEN }}</h1>
            </ion-label>
            <span *ngIf="!isKitchenExpanded" class="ion-text-end" (click)="isKitchenExpanded = true">
              <ion-icon name="chevron-down-outline"></ion-icon>
            </span>
            <span *ngIf="isKitchenExpanded" class="ion-text-end" (click)="isKitchenExpanded = false">
              <ion-icon name="chevron-up-outline"></ion-icon>
            </span>
          </ion-item>
          <app-expandable [expanded]="isKitchenExpanded">
            <ion-list lines="none">
              <div *ngFor="let item of kitchenItems">
                <app-personal-item [item]="item"></app-personal-item>
              </div>
            </ion-list>
          </app-expandable>
        </div>
    
        <div *ngIf="foodItems?.length > 0">
          <ion-item>
            <ion-label text-wrap>
              <h1>{{ packingCategoryTypes.FOOD }}</h1>
            </ion-label>
            <span *ngIf="!isFoodExpanded" class="ion-text-end" (click)="isFoodExpanded = true">
              <ion-icon name="chevron-down-outline"></ion-icon>
            </span>
            <span *ngIf="isFoodExpanded" class="ion-text-end" (click)="isFoodExpanded = false">
              <ion-icon name="chevron-up-outline"></ion-icon>
            </span>
          </ion-item>
          <app-expandable [expanded]="isFoodExpanded">
            <ion-list lines="none">
              <div *ngFor="let item of foodItems">
                <app-personal-item [item]="item"></app-personal-item>
              </div>
            </ion-list>
          </app-expandable>
        </div>
    
        <div *ngIf="clothingItems?.length > 0">
          <ion-item>
            <ion-label text-wrap>
              <h1>{{ packingCategoryTypes.CLOTHING }}</h1>
            </ion-label>
            <span *ngIf="!isClothingExpanded" class="ion-text-end" (click)="isClothingExpanded = true">
              <ion-icon name="chevron-down-outline"></ion-icon>
            </span>
            <span *ngIf="isClothingExpanded" class="ion-text-end" (click)="isClothingExpanded = false">
              <ion-icon name="chevron-up-outline"></ion-icon>
            </span>
          </ion-item>
          <app-expandable [expanded]="isClothingExpanded">
            <ion-list lines="none">
              <div *ngFor="let item of clothingItems">
                <app-personal-item [item]="item"></app-personal-item>
              </div>
            </ion-list>
          </app-expandable>
        </div>
    
        <div *ngIf="healthItems?.length > 0">
          <ion-item>
            <ion-label text-wrap>
              <h1>{{ packingCategoryTypes.HEALTH }}</h1>
            </ion-label>
            <span *ngIf="!isHealthExpanded" class="ion-text-end" (click)="isHealthExpanded = true">
              <ion-icon name="chevron-down-outline"></ion-icon>
            </span>
            <span *ngIf="isHealthExpanded" class="ion-text-end" (click)="isHealthExpanded = false">
              <ion-icon name="chevron-up-outline"></ion-icon>
            </span>
          </ion-item>
          <app-expandable [expanded]="isHealthExpanded">
            <ion-list lines="none">
              <div *ngFor="let item of healthItems">
                <app-personal-item [item]="item"></app-personal-item>
              </div>
            </ion-list>
          </app-expandable>
        </div>
    
        <div *ngIf="hygieneItems?.length > 0">
          <ion-item>
            <ion-label text-wrap>
              <h1>{{ packingCategoryTypes.HYGIENE }}</h1>
            </ion-label>
            <span *ngIf="!isHygieneExpanded" class="ion-text-end" (click)="isHygieneExpanded = true">
              <ion-icon name="chevron-down-outline"></ion-icon>
            </span>
            <span *ngIf="isHygieneExpanded" class="ion-text-end" (click)="isHygieneExpanded = false">
              <ion-icon name="chevron-up-outline"></ion-icon>
            </span>
          </ion-item>
          <app-expandable [expanded]="isHygieneExpanded">
            <ion-list lines="none">
              <div *ngFor="let item of hygieneItems">
                <app-personal-item [item]="item"></app-personal-item>
              </div>
            </ion-list>
          </app-expandable>
        </div>
    
        <div *ngIf="equipmentItems?.length > 0">
          <ion-item>
            <ion-label text-wrap>
              <h1>{{ packingCategoryTypes.EQUIPMENT }}</h1>
            </ion-label>
            <span *ngIf="!isEquipmentExpanded" class="ion-text-end" (click)="isEquipmentExpanded = true">
              <ion-icon name="chevron-down-outline"></ion-icon>
            </span>
            <span *ngIf="isEquipmentExpanded" class="ion-text-end" (click)="isEquipmentExpanded = false">
              <ion-icon name="chevron-up-outline"></ion-icon>
            </span>
          </ion-item>
          <app-expandable [expanded]="isEquipmentExpanded">
            <ion-list lines="none">
              <div *ngFor="let item of equipmentItems">
                <app-personal-item [item]="item"></app-personal-item>
              </div>
            </ion-list>
          </app-expandable>
        </div>
    
        <div *ngIf="entertainmentItems?.length > 0">
          <ion-item>
            <ion-label text-wrap>
              <h1>{{ packingCategoryTypes.ENTERTAINMENT }}</h1>
            </ion-label>
            <span *ngIf="!isEntertainmentExpanded" class="ion-text-end" (click)="isEntertainmentExpanded = true">
              <ion-icon name="chevron-down-outline"></ion-icon>
            </span>
            <span *ngIf="isEntertainmentExpanded" class="ion-text-end" (click)="isEntertainmentExpanded = false">
              <ion-icon name="chevron-up-outline"></ion-icon>
            </span>
          </ion-item>
          <app-expandable [expanded]="isEntertainmentExpanded">
            <ion-list lines="none">
              <div *ngFor="let item of entertainmentItems">
                <app-personal-item [item]="item"></app-personal-item>
              </div>
            </ion-list>
          </app-expandable>
        </div>
    
        <div *ngIf="accessoryItems?.length > 0">
          <ion-item>
            <ion-label text-wrap>
              <h1>{{ packingCategoryTypes.ACCESSORIES }}</h1>
            </ion-label>
            <span *ngIf="!isAccessoriesExpanded" class="ion-text-end" (click)="isAccessoriesExpanded = true">
              <ion-icon name="chevron-down-outline"></ion-icon>
            </span>
            <span *ngIf="isAccessoriesExpanded" class="ion-text-end" (click)="isAccessoriesExpanded = false">
              <ion-icon name="chevron-up-outline"></ion-icon>
            </span>
          </ion-item>
          <app-expandable [expanded]="isAccessoriesExpanded">
            <ion-list lines="none">
              <div *ngFor="let item of accessoryItems">
                <app-personal-item [item]="item"></app-personal-item>
              </div>
            </ion-list>
          </app-expandable>
        </div>
    
        <div *ngIf="miscellaneousItems?.length > 0">
          <ion-item>
            <ion-label text-wrap>
              <h1>{{ packingCategoryTypes.MISCELLANEOUS }}</h1>
            </ion-label>
            <span *ngIf="!isMiscellaneousExpanded" class="ion-text-end" (click)="isMiscellaneousExpanded = true">
              <ion-icon name="chevron-down-outline"></ion-icon>
            </span>
            <span *ngIf="isMiscellaneousExpanded" class="ion-text-end" (click)="isMiscellaneousExpanded = false">
              <ion-icon name="chevron-up-outline"></ion-icon>
            </span>
          </ion-item>
          <app-expandable [expanded]="isMiscellaneousExpanded">
            <ion-list lines="none">
              <div *ngFor="let item of miscellaneousItems">
                <app-personal-item [item]="item"></app-personal-item>
              </div>
            </ion-list>
          </app-expandable>
        </div>
      </ion-row> -->



































      
    </ion-card-content>
  </ion-card>
</div>
