/*
::::::::WARNING WARNING WARNING::::::::

        BEWARE THE MAGIC STRINGS
        ANY CHANGE TO THIS OBJECT WILL BREAK THINGS IN THE API!!!
*/



export const ViewTripTabTypes = {
    CHAT: "Chat",
    ATTENDEES: "Attendees",
    TRIP_DETAILS: "Trip Details",
    PERSONAL_INVENTORY: "Personal",
    GROUP_INVENTORY: "Group",
    SHARED_MEALS: "Shared Meals",
    CAR_POOLING: "Car Pooling"
}

export const PriorityTypes = {
    IMPORTANT: "Important",
    OPTIONAL: "Optional"
}


export const ProvisionStatusTypes = {
    UNTOUCHED: "Untouched",
    NEEDED: "Needed",
    PROVISIONED: "Provisioned"
}

export const HostTitleTypes = {
    HOST: "Host",
    LEADER: "Leader",
    COUNSELOR: "Counselor",
    DIRECTOR: "Director",
    STAFF: "Staff",
    MENTOR: "Mentor",
    INSTRUCTOR: "Instructor",
    HBIC: "HBIC"
}
export const InvitationStatusTypes = {
    INVITED: "Invited",
    MAYBE: "Maybe",
    ACCEPTED: "Accepted"
}

export const PackingCategoryTypes = {
    CLOTHING: "Clothing",
    EQUIPMENT: "Equipment",
    ACCESSORIES: "Accessories",
    CAMP_SITE: "Camp Site",
    TOOLS: "Tools",
    HYGIENE: "Hygene",
    KITCHEN: "Kitchen",
    FOOD: "Food",
    HEALTH: "Health",
    MISCELLANEOUS: "Miscellaneous",
    TRANSPORTATION: "Transportation",
    ENTERTAINMENT: "Entertainment"
}

export const SharedMealTypes = {
    BREAKFAST: "Breakfast",
    LUNCH: "Lunch",
    DINNER: "Dinner",
    SNACK: "Snack",
    OTHER: "Other"
}

export const VehicleTypes = {
    CAR: "Car",
    TRUCK: "Truck",
    VAN: "Van",
    RV: "RV",
    BUS: "Bus",
    MOTORCYCLE: "Motorcycle",
    MINIVAN: "Minivan",
    OTHER: "Other"
}


