
<div class="modal-wrapper">
  <ion-card *ngIf="currentPage === createTripPageTypes.PAGE_ONE" class="modal-card-wrapper">
    <ion-card-header class="logo-wrapper">
      <h1 class="title">New Trip!</h1>
      <ion-icon class="close-icon clickable" (click)="onClose($event)" name="close-outline"></ion-icon>
      <!-- <span class="close-icon clickable" (click)="onSave($event)">Save</span> -->
    </ion-card-header>
  
    <ion-card-content class="modal-content-wrapper">
      <form [formGroup]="newTripPageOneForm">
        <ion-row>
          <ion-col>
            <ion-item class="input-item">
              <ion-input placeholder="Trip name or title" [formControlName]="'name'"></ion-input>
              <ion-icon class="form-icon" slot="end" name="pencil-outline"></ion-icon>
            </ion-item>
            <div *ngIf="newTripPageOneForm.controls.name.invalid && newTripPageOneForm.controls.name.touched">
              <ion-text color="danger" *ngIf="newTripPageOneForm.controls.name.errors?.['required']">
                  Required Field
              </ion-text>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <p class="section-title">When will the trip be?</p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-datetime (ionChange)="selectDate($event)" presentation="date" [highlightedDates]="highlightedDates"></ion-datetime>
            <div *ngIf="newTripPageOneForm.controls.startDateUtc.invalid && newTripPageOneForm.controls.startDateUtc.touched ||
            newTripPageOneForm.controls.endDateUtc.invalid && newTripPageOneForm.controls.endDateUtc.touched">
              <ion-text color="danger" *ngIf="newTripPageOneForm.controls.startDateUtc.errors?.['required'] || newTripPageOneForm.controls.endDateUtc.errors?.['required']">
                You must choose a start and an end date <br> (you can change this later)
              </ion-text>
            </div>
          </ion-col>
        </ion-row>
      </form>
      <ion-row>
        <ion-col>
            <ion-button (click)="goToPage2()">Next</ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button (click)="onClose($event)">Cancel</ion-button>
        </ion-col>
      </ion-row>
    </ion-card-content>
  </ion-card>

  <ion-card *ngIf="currentPage === createTripPageTypes.PAGE_TWO" class="modal-card-wrapper">
    <ion-card-header class="logo-wrapper">
      <h1 class="title">{{ newTripPageOneForm.controls.name.value }}</h1>
      <ion-icon class="close-icon clickable" (click)="onClose($event)" name="close-outline"></ion-icon>
      <!-- <span class="close-icon clickable" (click)="onSave($event)">Save</span> -->
    </ion-card-header>
  
    <ion-card-content class="modal-content-wrapper">
      <form [formGroup]="newTripPageTwoForm">
        <ion-row>
          <ion-col>
            <p class="section-title">Where will the trip be?</p>
            <p class="ion-text-center">click on the map or search</p>
          </ion-col>
        </ion-row>
        
        <div class="mapbox-container">
          <div *ngIf="newTripPageTwoForm.controls.location.invalid && newTripPageTwoForm.controls.location.touched">
            <ion-text color="danger" *ngIf="newTripPageTwoForm.controls.location.errors?.['required']">
              Must choose a location (you can change this later)
            </ion-text>
          </div>
          <div #mapElement id="mapbox"></div>
          <!-- <ion-button class="clear-selected-button" *ngIf="showLocation" (click)="clearLocationSelection()">Clear Location</ion-button> -->
          
        </div>
          
        
      </form>
      <ion-row>
        <ion-col>
            <ion-button (click)="goToPage3()">Next</ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button (click)="onClose($event)">Cancel</ion-button>
        </ion-col>
      </ion-row>
    </ion-card-content>
  </ion-card>

  <ion-card *ngIf="currentPage === createTripPageTypes.PAGE_THREE" class="modal-card-wrapper">
    <ion-card-header class="logo-wrapper">
      <h1 class="title">New Trip!</h1>
      <ion-icon class="close-icon clickable" (click)="onClose($event)" name="close-outline"></ion-icon>
      <!-- <span class="close-icon clickable" (click)="onSave($event)">Save</span> -->
    </ion-card-header>
  
    <ion-card-content class="modal-content-wrapper">
      <form [formGroup]="newTripPageThreeForm">
        <ion-row>
          <ion-col>
            <p class="section-title">Banner Photo</p>
            <p class="ion-text-center">Choose a picture that represents the trip</p>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="!showBannerPhotoLinkInput && !isUploadingImage">
          <ion-col>
            <ion-button (click)="showBannerPhotoLinkInput = true">Use a Link</ion-button>
          </ion-col>
          <ion-col>
            <div class="select-photo-input-wrapper upload-btn">
              <ion-button (click)="imgFile.click()">Upload</ion-button>
              <input type="file" #imgFile [multiple]="false" accept="capture=camera,image/*" (change)="selectImg($event)" style="display:none"/>
            </div>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="isUploadingImage">
          <ion-col>
            <div *ngIf="fileEvent === fileEventTypes.WRONG_FILE_TYPE_DETECTED" class="wrong-file-type">
              <h3>The file type uploaded is not accepted.</h3>
              <p>Accepted files types are <span class="accepted-file-types">PNG</span>, <span class="accepted-file-types">JPEG</span>, or <span class="accepted-file-types">JPG</span></p>  
            </div>
    
            <div *ngIf="(fileEvent === fileEventTypes.IMAGE_LOADING)" class="loading">
              <p>Loading image...</p>
              <div class="spinner-wrapper">
                <ion-spinner class="spinner" name="crescent"></ion-spinner>
              </div>
            </div>
            <app-trip-preview *ngIf="tripPreview" [trip]='tripPreview'></app-trip-preview>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="showBannerPhotoLinkInput">
          <ion-col>
            <ion-item class="input-item">
              <ion-input placeholder="Banner Photo Link" [formControlName]="'bannerPhotoUrl'"></ion-input>
              <ion-icon (click)="showBannerPhotoLinkInput = false" class="form-icon" slot="end" name="close-circle-outline"></ion-icon>
            </ion-item>
            <div *ngIf="newTripPageThreeForm.controls.bannerPhotoUrl.invalid && newTripPageThreeForm.controls.bannerPhotoUrl.touched">
              <ion-text color="danger" *ngIf="newTripPageThreeForm.controls.bannerPhotoUrl.errors?.['required']">
                Must choose a banner photo <br> (you can change this later, but it's highly recommended that you choose a good picture)
              </ion-text>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item class="input-item">
              <ion-input placeholder="Helpful Link (i.e. campsite home page...)" [formControlName]="'helpfulLinkUrl'"></ion-input>
              <ion-icon class="form-icon" slot="end" name="pencil-outline"></ion-icon>
            </ion-item>
            <!-- <div *ngIf="newTripPageThreeForm.controls.bannerPhotoUrl.invalid && newTripPageThreeForm.controls.bannerPhotoUrl.touched">
              <ion-text color="danger" *ngIf="newTripPageThreeForm.controls.bannerPhotoUrl.errors?.['required']">
                  Required Field
              </ion-text>
            </div> -->
          </ion-col>
        </ion-row>


        <ion-row>
          <ion-col>
            <ion-item class="bordered-textarea-item" lines="none">
              <ion-label position="stacked">Notes</ion-label>
              <ion-textarea [formControlName]="'notes'"></ion-textarea>
            </ion-item>
          </ion-col>
        </ion-row>
      </form>
      <ion-row>
        <ion-col>
            <ion-button (click)="save()">Save</ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button (click)="onClose($event)">Cancel</ion-button>
        </ion-col>
      </ion-row>
    </ion-card-content>
  </ion-card>
</div>
