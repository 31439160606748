import { PersonalItem, PersonalItemStatus, PersonalProvision } from "../services/treelove-api.service";

export class PersonalItemDisplay extends PersonalItem {
    constructor(item: PersonalItem, currentCamperId: string) {
        super(item);
        this.currentCamperStatus = this.camperStatuses.find(x => x.camperId === currentCamperId);

        this.currentCamperDoesHave = this.currentCamperStatus?.haveCount === item.requiredCount;
        this.currentCamperDoesNeed = this.currentCamperStatus?.needCount > 0        

        // this.campersStatusesWhoNeedToBorrow = this.camperStatuses.filter(x => x.camperId != currentCamperId && x.needCount > 0);

        this.campersStatusesWhoNeedToBorrow = this.camperStatuses.filter(x => {
            if(x.camperId === currentCamperId) return false;
            if(x.needCount === 0) return false;

            var receivingProvisions = this.provisions?.filter(y => y.receiverId === x.camperId);
            var recievingCount = 0;
            if (receivingProvisions?.some(x => true)) {
                recievingCount = receivingProvisions.map(y => y.provisionedCount).reduce((total, count) => total + count);
            }
            var stillInNeed = recievingCount < x.needCount;

            return stillInNeed;
        });

        this.currentCamperReceiverProvisions = this.provisions?.filter(x => x.receiverId === currentCamperId);
        this.currentCamperProviderProvisions = this.provisions?.filter(x => x.providerId === currentCamperId);

        this.currentCamperIsBorrowing = this.currentCamperReceiverProvisions?.length > 0;
        this.currentCamperIsLending = this.currentCamperProviderProvisions?.length > 0;
        this.someoneElseDoesNeedToBorrow = this.campersStatusesWhoNeedToBorrow?.length > 0;
    }

    changeCamperStatus(provisionerId: string) {

    }

    isExpanded: boolean = false;
    campersStatusesWhoNeedToBorrow: PersonalProvision[];
    currentCamperReceiverProvisions: PersonalProvision[];
    currentCamperProviderProvisions: PersonalProvision[];

    currentCamperStatus: PersonalItemStatus;
    currentCamperDoesHave: boolean;
    currentCamperDoesNeed: boolean;
    currentCamperIsBorrowing: boolean;
    currentCamperIsLending: boolean;
    someoneElseDoesNeedToBorrow: boolean;
}