import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthFlowPageModule } from './auth/auth-flow/auth-flow.module';
import { HeaderGuard } from './guards/header.guard';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  {
    path: 'legal',
    loadChildren: () => import('./pages/legal/legal.module').then( m => m.LegalPageModule),
    data: { title: 'Legal', showAuthTopper: true }
  },
  {
    path: 'auth-flow',
    loadChildren: () => AuthFlowPageModule,
    canActivate: [ HeaderGuard ],
    data: { title: 'Sign In', showAuthTopper: true }
  },
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: ':inviteId/:tripId',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [ AuthGuard ]
  },

  {
    path: ':inviteId',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [ AuthGuard ]
  }
  
  
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
