import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TreeloveAvatarComponent } from './treelove-avatar/treelove-avatar.component';
import { CarpoolRideWithRequest, TripPreview } from '../services/treelove-api.service';
import { TripPreviewComponent } from './trip-preview/trip-preview.component';
import { ChooseCamperPopoverComponent } from './choose-camper-popover/choose-camper-popover.component';
import { ExpandableComponent } from './expandable/expandable.component';
import { TlHeaderComponent } from './tl-header/tl-header.component';
import { SharedMealSelectionModalComponent } from './modals/shared-meal-selection-modal/shared-meal-selection-modal.component';
import { CarpoolCreationModalComponent } from './modals/carpool-creation-modal/carpool-creation-modal.component';
import { PersonalItemCreationModalComponent } from './modals/personal-item-creation-modal/personal-item-creation-modal.component';
import { GroupItemCreationModalComponent } from './modals/group-item-creation-modal/group-item-creation-modal.component';
import { TripCreationModalComponent } from './modals/trip-creation-modal/trip-creation-modal.component';
import { RsvpSelectionModal as RsvpSelectionModal } from './modals/rsvp-selection-modal/rsvp-selection-modal.component';
import { PersonalItemSelectionModal } from './modals/personal-item-selection-modal/personal-item-selection-modal.component';
import { GroupItemSelectionModal } from './modals/group-item-selection-modal/group-item-selection-modal.component';
import { GameCreationModalComponent } from './modals/game-creation-modal/game-creation-modal.component';

@NgModule({
  declarations: [
    TreeloveAvatarComponent,
    TripPreviewComponent,
    ChooseCamperPopoverComponent,
    ExpandableComponent,
    TlHeaderComponent,
    PersonalItemSelectionModal,
    GroupItemSelectionModal,
    SharedMealSelectionModalComponent,
    CarpoolCreationModalComponent,
    PersonalItemCreationModalComponent,
    GroupItemCreationModalComponent,
    TripCreationModalComponent,
    GameCreationModalComponent,
    RsvpSelectionModal
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    TreeloveAvatarComponent,
    TripPreviewComponent,
    ChooseCamperPopoverComponent,
    ExpandableComponent,
    TlHeaderComponent,
    PersonalItemSelectionModal,
    GroupItemSelectionModal,
    SharedMealSelectionModalComponent,
    CarpoolCreationModalComponent,
    PersonalItemCreationModalComponent,
    GroupItemCreationModalComponent,
    TripCreationModalComponent,
    GameCreationModalComponent,
    RsvpSelectionModal
  ]
})
export class SharedModule { }
