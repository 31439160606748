import { GroupItem, Carpool, Connect4EverGame } from "../services/treelove-api.service";

export class Connect4EverGameDisplay extends Connect4EverGame {
  currentPlayerId: string;
  opponentPlayerId: string;
  totalRows: number = 6;
  totalCols: number = 7;
  pointThreshold: number = 21;

  constructor(game: Connect4EverGame = null, currentPlayerId: string = null) {
    super(game);
    if(currentPlayerId) {
      this.currentPlayerId = currentPlayerId;
      if(this.playerOneId && this.playerTwoId) {
        this.opponentPlayerId = currentPlayerId === this.playerOneId ? this.playerTwoId : this.playerOneId;
      }
    }
  }

  resetGame() {
    this.gameState = Array.from({ length: this.totalRows }, () => Array(this.totalCols).fill(null));
    this.isGameOver = false;
    this.winnerId = null;
    this.lastMovePlayerId = null;
    this.playerOnePoints = 0;
    this.playerTwoPoints = 0;
  }

  makeMove(col: number) {
    if (this.currentPlayerId === this.lastMovePlayerId || this.isGameOver) return false;

    // Add new rows if the bottom rows are filled
    const requiredFullRows = this.totalRows - 2;
    if (this.areBottomRowsFull(requiredFullRows)) {
        this.addRows();
    }

    // Check from the top row downwards for the first available space
    for (let row = this.totalRows - 1; row >= 0; row--) {
      if (!this.gameState[row][col]) {
        this.lastMoveRow = row;
        this.lastMoveCol = col;
        this.gameState[row][col] = this.currentPlayerId;
        const points = this.checkWin(row, col, this.currentPlayerId);
        if (points > 0) {
          this.addPoints(this.currentPlayerId, points);
          if (this.checkGameOver()) {
            this.isGameOver = true;
            this.winnerId = this.currentPlayerId;
          }
        } else {
          this.lastMovePlayerId = this.currentPlayerId;
        }
        return true;
      }
    }
    return false;
  }


  addRows() {
    for (let i = 0; i < 6; i++) {
      
        this.gameState.unshift(Array(this.totalCols).fill(null));
        this.totalRows++;
    }
  }

  areBottomRowsFull(requiredFullRows: number): boolean {
    for (let row = this.totalRows - requiredFullRows; row < this.totalRows; row++) {
      if (this.gameState[row].some(cell => cell === null)) {
        return false;
      }
    }
    return true;
  }

  checkDirection(row: number, col: number, playerId: string, rowDir: number, colDir: number): number {
    let count = 0;
    let maxCount = 0;

    const limit = (rowDir === 0 || colDir === 0) ? this.totalCols : Math.max(this.totalRows, this.totalCols);

    for (let i = -limit; i <= limit; i++) {
        const r = row + i * rowDir;
        const c = col + i * colDir;
        if (r >= 0 && r < this.totalRows && c >= 0 && c < this.totalCols && this.gameState[r][c] === playerId) {
            count++;
            maxCount = Math.max(maxCount, count);
        } else {
            count = 0;
        }
    }

    return maxCount >= 4 ? maxCount - 3 : 0;
  }

  checkVertical(row: number, col: number, playerId: string): number {
      let count = 0;
      let maxCount = 0;

      for (let r = row; r < this.totalRows; r++) {
          if (this.gameState[r][col] === playerId) {
              count++;
              maxCount = Math.max(maxCount, count);
          } else {
              break;
          }
      }

      return maxCount >= 4 ? maxCount - 3 : 0;
  }

  checkWin(row: number, col: number, playerId: string): number {
      return (
          this.checkDirection(row, col, playerId, 0, 1) +  // Horizontal
          this.checkVertical(row, col, playerId) +         // Vertical
          this.checkDirection(row, col, playerId, 1, 1) +  // Diagonal /
          this.checkDirection(row, col, playerId, 1, -1)   // Diagonal \
      );
  }


  addPoints(playerId: string, points: number) {
    if (playerId === this.playerOneId) {
      this.playerOnePoints += points;
    } else if (playerId === this.playerTwoId) {
      this.playerTwoPoints += points;
    }
  }
  

  checkGameOver(): boolean {
    const pointDifference = Math.abs(this.playerOnePoints - this.playerTwoPoints);
    return (pointDifference >= this.winByThreshold) && (this.playerOnePoints >= this.pointThreshold || this.playerTwoPoints >= this.pointThreshold);
  }
}
