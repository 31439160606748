import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { InvitationStatusTypes, SharedMealTypes, VehicleTypes } from 'src/app/models/constants';
import { TripDisplay } from 'src/app/models/trip-display';
import { CamperProfile, Carpool, SharedMeal, Trip } from 'src/app/services/treelove-api.service';

@Component({
  selector: 'app-carpool-creation-modal',
  templateUrl: './carpool-creation-modal.component.html',
  styleUrls: ['./carpool-creation-modal.component.scss'],
})
export class CarpoolCreationModalComponent implements OnInit {
  
  @Input() trip: TripDisplay;
  @Input() currentCamper: CamperProfile;
  campersToPIck: CamperProfile[];
  @Input() item: Carpool = new Carpool();
  // public get item(): Carpool {
  //   return this._item;
  // }
  // public set item(value: Carpool) {
  //   this._item = value;
  // }
  vehicleTypes: string[] = [
    VehicleTypes.CAR,
    VehicleTypes.TRUCK,
    VehicleTypes.VAN,
    VehicleTypes.RV,
    VehicleTypes.BUS,
    VehicleTypes.MOTORCYCLE,
    VehicleTypes.MINIVAN,
    VehicleTypes.OTHER,
  ];

  availableSeatsCount = 1;

  public carpoolForm = new FormGroup({
    vehicleType: new FormControl('', [ Validators.required ]),
    notes: new FormControl('', [ ]),
    providerId: new FormControl('', [ Validators.required ]),
  });
  constructor(private _modalController: ModalController) { }

  ionViewDidEnter() {
    const modalCardHeight = document.querySelector('.modal-card-wrapper')?.clientHeight;
    const modalCardWidth = document.querySelector('.modal-card-wrapper')?.clientWidth;
    document.documentElement.style.setProperty('--current-modal-card-height', `${modalCardHeight}px`);
    document.documentElement.style.setProperty('--current-modal-card-width', `${modalCardWidth}px`);
    this.setupOptions();
  }

  setupOptions() {
    if(this.trip.currentCamperIsHost) {
      this.campersToPIck = this.trip.attendees.filter(x => x.status === InvitationStatusTypes.ACCEPTED)
        .filter(x => x.camper.id !== this.currentCamper.id)
        .map(x => x.camper);
    } else {
      this.carpoolForm.controls.providerId.setValue(this.currentCamper.id);
    }

    if(this.item.id) {
      this.carpoolForm.controls.providerId.setValue(this.item.providerId);
      this.carpoolForm.controls.vehicleType.setValue(this.item.vehicleType);
      
      this.availableSeatsCount = this.item.availableSeatsCount > 1 ? this.item.availableSeatsCount : 1;
      
    }
  }

  increaseQuantity() {
      this.availableSeatsCount += 1;
  }

  decreaseQuantity() {
    if(this.availableSeatsCount > 1) {
      this.availableSeatsCount -= 1;
    }
  }

  ngOnInit() {}

  onClose(event) {
    this._modalController.dismiss(null);
  }

  onSave(event) {
    if(!this.carpoolForm.valid) {
      this.carpoolForm.markAllAsTouched();
    } else {
      this.item.vehicleType = this.carpoolForm.controls.vehicleType.value;
      this.item.availableSeatsCount = this.availableSeatsCount;
      this.item.notes = this.carpoolForm.controls.notes.value;
      this.item.providerId = this.carpoolForm.controls.providerId.value;
      this._modalController.dismiss(this.item);
    }
  }

}
