import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { InvitationStatusTypes, SharedMealTypes } from 'src/app/models/constants';
import { TripDisplay } from 'src/app/models/trip-display';
import { CamperEntity, CamperProfile, CamperProfileResponse, Carpool, SharedMeal, Trip } from 'src/app/services/treelove-api.service';
import { TripService } from 'src/app/services/trip.service';

export class RsvpSelectionResponse {
  camperRsvpId: string;
  isParticipating: boolean;
}
@Component({
  selector: 'app-rsvp-selection-modal',
  templateUrl: './rsvp-selection-modal.component.html',
  styleUrls: ['./rsvp-selection-modal.component.scss'],
})
export class RsvpSelectionModal  implements OnInit {
  
  @Input() trip: TripDisplay;
  @Input() currentCamper: CamperProfile;
  @Input() activityQuery: string;
  @Input() rsvpTitle: string;
  @Input() doesCurrentCamperProvide: boolean = false;
  @Input() carpool: Carpool;
  isCarFull: boolean = false;
  campersToPIck: CamperProfile[];
  

  public rsvpForm = new FormGroup({
    isParticipating: new FormControl(true, [ Validators.required ]),
    providerId: new FormControl('', [ Validators.required ]),
  });
  constructor(private _modalController: ModalController, private _tripService: TripService) { }

  ionViewDidEnter() {
    const modalCardHeight = document.querySelector('.modal-card-wrapper')?.clientHeight;
    const modalCardWidth = document.querySelector('.modal-card-wrapper')?.clientWidth;
    document.documentElement.style.setProperty('--current-modal-card-height', `${modalCardHeight}px`);
    document.documentElement.style.setProperty('--current-modal-card-width', `${modalCardWidth}px`);
    this.setupOptions();
  }

  ngOnInit() {}

  onClose(event) {
    this._modalController.dismiss(null);
  }

  onSave(event) {
    if(!this.rsvpForm.valid) {
      this.rsvpForm.markAllAsTouched();
    } else {
      var response = new RsvpSelectionResponse();
      response.camperRsvpId = this.rsvpForm.controls.providerId.value;
      response.isParticipating = this.rsvpForm.controls.isParticipating.value;
      this._modalController.dismiss(response);
    }
  }

  setupOptions() {
    
    if(this.trip.currentCamperIsHost || this.doesCurrentCamperProvide) {
      this.campersToPIck = this.trip.attendees.filter(x => x.status === InvitationStatusTypes.ACCEPTED)
        .filter(x => x.camper.id !== this.currentCamper.id)
        .map(x => x.camper);
      if(this.carpool && this.carpool.riderIds.length === this.carpool.availableSeatsCount) {
        this.campersToPIck = this.campersToPIck.filter(x => this.carpool.riderIds.includes(x.id));
        this.isCarFull = true;
      } 
    } else {
      this.rsvpForm.controls.providerId.setValue(this.currentCamper.id);
    }
  }
  getCamper(camperId: string) : CamperEntity {
    return this._tripService.getCamper(camperId);
  }
  

}
