import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-personal-item-selection-modal',
  templateUrl: './personal-item-selection-modal.component.html',
  styleUrls: ['./personal-item-selection-modal.component.scss'],
})
export class PersonalItemSelectionModal implements OnInit {
  @Input() mainText: string;
  @Input() subText: string;
  @Input() quantity: number;
  @Input() total: number;
  constructor(private _modalController: ModalController) { }

  ionViewDidEnter() {
    const modalCardHeight = document.querySelector('.modal-card-wrapper')?.clientHeight;
    const modalCardWidth = document.querySelector('.modal-card-wrapper')?.clientWidth;
    document.documentElement.style.setProperty('--current-modal-card-height', `${modalCardHeight}px`);
    document.documentElement.style.setProperty('--current-modal-card-width', `${modalCardWidth}px`);
  }

  ngOnInit() {}

  onClose(event) {
    this._modalController.dismiss(null);
  }

  onSave(event) {
    this._modalController.dismiss(this.quantity);
  }

  increaseQuantity() {
    // if(this.quantity < this.total) {
      this.quantity += 1;
    // }
  }

  decreaseQuantity() {
    if(this.quantity > 0) {
      this.quantity -= 1;
    }
  }

}
