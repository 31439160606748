import { GroupItem, GroupProvision } from "../services/treelove-api.service";

export class GroupItemDisplay extends GroupItem {
    constructor(item: GroupItem, currentCamperId: string) {
        super(item);
        this.currentCamperProviderProvision = this.provisions.find(x => x.providerId === currentCamperId);
        if(this.currentCamperProviderProvision) {
            this.currentCamperIsProviding = true;
        }

        this.otherCamperProvidersProvisions = this.provisions.filter(x => x.providerId !== currentCamperId);
        this.otherCampersAreProviding = this.otherCamperProvidersProvisions?.length > 0;
        if(this.provisions?.length > 0) {
            this.totalProvisionsProvided = this.provisions?.map(x => x.provisionedCount).reduce((total, count) => total + count);
        }
    }

    isExpanded: boolean = false;

    currentCamperProviderProvision: GroupProvision;
    currentCamperIsProviding: boolean;
    otherCamperProvidersProvisions: GroupProvision[];
    otherCampersAreProviding: boolean;

    totalProvisionsProvided: number = 0;
    
    
}