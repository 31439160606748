
  <div class="modal-wrapper">
    <ion-card class="modal-card-wrapper">
      <ion-card-header class="logo-wrapper">
        <h1 class="title">Group Item</h1>
        <ion-icon class="close-icon clickable" (click)="onClose($event)" name="close-outline"></ion-icon>
        <!-- <span class="close-icon clickable" (click)="onSave($event)">Save</span> -->
      </ion-card-header>
    
      <ion-card-content class="modal-content-wrapper">
        <form [formGroup]="quantitySelectionForm">
          <ion-row *ngIf="trip.currentCamperIsHost && isHostAction">
            <ion-col>
              <ion-item style="min-width: 84vw;" class="bordered-item" lines="none">
                  <ion-select (ionChange)="setQuantity()" cancelText="Cancel" okText="Done" [formControlName]="'providerId'" placeholder="Who are you assigning this item to?">
                    <ion-select-option [value]="currentCamper.id">Me</ion-select-option>
                    <ion-select-option *ngFor="let camper of campersToPIck" [value]="camper.id">{{ camper.name }}</ion-select-option>
                  </ion-select>
                <ion-icon slot="end" name="accessibility-outline"></ion-icon>
              </ion-item>
              <div *ngIf="quantitySelectionForm.controls.providerId.invalid && quantitySelectionForm.controls.providerId.touched">
                <ion-text color="danger" *ngIf="quantitySelectionForm.controls.providerId.errors?.['required']">
                    Required Field
                </ion-text>
              </div>
            </ion-col>
          </ion-row>
          <div *ngIf="quantitySelectionForm.controls.providerId.value">
            <ion-row>
              <ion-col class="ion-text-center">
                <p *ngIf="quantitySelectionForm.controls.providerId.value === currentCamper.id" class="text-notice">How many {{ item.name }} can you bring for the group?</p>
                <p *ngIf="quantitySelectionForm.controls.providerId.value !== currentCamper.id" class="text-notice">How many {{ item.name }} can {{ getCamper(quantitySelectionForm.controls.providerId.value).name }} bring for the group?</p>
              </ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center">
              <ion-col offset="1" size="3">
                <ion-button (click)="decreaseQuantity()" class="quantity-selection-button"><ion-icon slot="icon-only" name="remove-outline"></ion-icon></ion-button>
              </ion-col>
              <ion-col size="4" class="ion-align-self-center ion-text-center">
                <h2 class="quantity-selection-quantity">{{ quantitySelectionForm.controls.quanity.value }} / {{ total }}</h2>
              </ion-col>
              <ion-col size="3">
                <ion-button (click)="increaseQuantity()"
                class="quantity-selection-button"><ion-icon slot="icon-only" name="add-outline"></ion-icon></ion-button>
              </ion-col>
            </ion-row>
          </div>
        </form>
        <ion-row>
          <ion-col>
              <ion-button (click)="onSave($event)">Save</ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button (click)="onClose($event)">Cancel</ion-button>
          </ion-col>
        </ion-row>

        <!-- <div>
          <p class="text-notice">{{ mainText }}</p>
          <ion-button class="quantity-selection-button"><ion-icon slot="icon-only" name="remove-outline"></ion-icon></ion-button>
          <h2 class="quantity-selection-quantity">{{ quantity }} / {{ total }}</h2>
          <ion-button class="quantity-selection-button"><ion-icon slot="icon-only" name="add-outline"></ion-icon></ion-button>

            <ion-list>
              <ion-item [lines]="'none'">
                <ion-input inputmode="numeric" [(ngModel)]="quantity" placeholder="How many?" type="number" description="numeric"></ion-input>
              </ion-item>
            </ion-list>
    
            <ion-item-divider></ion-item-divider>
            <div class="action-btn-wrapper">
              <ion-button (click)="onSave($event)">Save</ion-button>
              <ion-button (click)="onClose($event)">Cancel</ion-button>
            </div>
        </div> -->
      </ion-card-content>
    </ion-card>
  </div>
